import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['percentage', 'percentageValue']
  connect() {
    this.percentageTarget.style.backgroundSize = `${this.percentageTarget.value}% 100%`
    this.percentageTarget.addEventListener('input', (event) => {
      const { value, min, max, step, parentElement: parent } = event.currentTarget
      this.percentageValueTarget.innerHTML = `${value} %`
      this.percentageTarget.style.backgroundSize = `${value}% 100%`
    });  
  }
}