import { Controller } from '@hotwired/stimulus'
import { toCurrency } from '../helpers'

export default class extends Controller {
  static targets = ['invoiceId', 'payment', 'totalAmount', 'attachButton']

  connect() {
    console.log('conected')
    this.attachButtonAdjustment()
  }

  totalAmountTargetConnected(element) {
    element.addEventListener('change', (event) => {
      this.setPayments(event.currentTarget)
    })
  }

  invoiceIdTargetConnected(element) {
    this.attachButtonAdjustment()
    element.addEventListener('change', (event) => {
      this.setInvoiceData(event.currentTarget)
    })
  }

  invoiceIdTargetDisconnected() {
    console.log('disconected')
    this.attachButtonAdjustment()
  }

  setInvoiceData(invoiceField) {
    let paymentRow = invoiceField.closest('.table-row');
    let totalField = paymentRow.querySelector('[data-total-value]')
    let balanceField = paymentRow.querySelector('[data-balance-value]')

    let idx = invoiceField.selectedIndex;
    if (idx == -1) { return }
    let total = invoiceField.options[idx].dataset.total || '';
    let balance = invoiceField.options[idx].dataset.balance || '';

    console.log(totalField)
    totalField.setAttribute('data-total-value', total);
    totalField.innerHTML = toCurrency(total)
    balanceField.setAttribute('data-balance-value', balance);
    balanceField.innerHTML = toCurrency(balance)
  }

  setPayments(totalAmountField) {
    const totalAmount = totalAmountField.value * 1
    let remainingAmount = totalAmount;
    this.paymentTargets.forEach(element => {
      let amountField = element.querySelector('[name$="[amount]"]');
      let balance = element.querySelector('[data-balance-value]').dataset.balanceValue * 1
      if (remainingAmount >= balance) {
        amountField.value = balance
      } else {
        if (remainingAmount < 0) { remainingAmount = 0 }
        amountField.value = remainingAmount
      }
      remainingAmount -= balance
    });
    if (remainingAmount > 0 && this.canAttachPayment()) {
      this.attachButtonTarget.click()
      if (this.totalAmountTarget.value * 1 > this.paymentsAmount()) {
        let available = this.unpaidInvoices().filter(n => !this.selectedInvoices().includes(n))
        if (available.length > 0) {
          let emptyInvoiceFields = Array.from(this.invoiceIdTargets).filter(input => input.value == '')
          if (emptyInvoiceFields.length > 0) {
            emptyInvoiceFields[0].value = available[0]
            if (emptyInvoiceFields[0].slim) emptyInvoiceFields[0].slim.set(available[0])
            this.setInvoiceData(emptyInvoiceFields[0])
            this.setPayments(this.totalAmountTarget)
          }
        }
      }
    }
  }

  unpaidInvoices() {
    return Array.from(this.invoiceIdTarget)
      .filter(input => input.value != '')
      .map(input => input.value);
  }

  selectedInvoices() {
    return Array.from(this.invoiceIdTargets).map(input => input.value).filter(i => i != '')
  }

  attachButtonAdjustment() {
    if (this.canAttachPayment()) {
      this.attachButtonTarget.classList.remove('hidden')
    } else {
      this.attachButtonTarget.classList.add('hidden')
    }
  }

  canAttachPayment() {
    return this.unpaidInvoices().length > this.invoiceIdTargets.length
  }

  paymentsAmount() {
    let payments = Array.from(this.paymentTargets)
      .map(element => element.querySelector('[name$="[amount]"]').value)
      .filter(i => i != '')
    let sum = 0
    payments.forEach(item => { sum += item * 1; });
    return sum
  }
}