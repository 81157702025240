import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js'

export default class extends Controller {
  currentOwnerId;

  static targets = ['type', 'ownerField', 'owner', 'leaseRentField', 'leaseField',
    'mileage', 'mileageFields', 'sublease', 'subleaseFields', 'subleaseLeaseField']

  static values = {
    newrecord: Boolean
  }

  connect() {
    this.setVisibleFields();
    this.mileageTargets.forEach((item, i) => {
      this.setMileageFields(item);
    })
    this.currentOwnerId = this.ownerTarget.value
  }

  subleaseFieldsTargetConnected() {
    this.setVisibleFields();
  }

  mileageTargetConnected(element) {
    this.setMileageFields(element);
  }

  ownerFieldTargetConnected(element) {
    const type = this.typeTarget.value
    if (type == 'company') {
      element.classList.add('hidden');
    } else {
      element.classList.remove('hidden');
    }
  }

  setVisibleFields() {
    const controller = this
    let addIndependentContractorButton = document.getElementById('pu_add_new_independent_contractor');
    let addLeaseRentCompanyButton = document.getElementById('pu_add_new_lease_rent_company');

    const type = controller.typeTarget.value
    if (type == 'company') {
      controller.ownerFieldTarget.classList.add('hidden');
    } else {
      controller.ownerFieldTarget.classList.remove('hidden');
    }

    if (controller.newrecordValue) {
      controller.mileageTargets.forEach((item, i) => {
        if (type == 'lease' || type == 'rent') {
          item.checked = true;
        } else {
          item.checked = false;
        }
        controller.setMileageFields(item);
      });
    }

    if (document.getElementById('power_unit_status') && (document.getElementById('power_unit_status').value == 'subunit')) {
      controller.subleaseTarget.classList.add('hidden-box');
    } else if (type != 'independent_contractor') {
      controller.subleaseTarget.classList.remove('hidden-box');
    } else {
      controller.subleaseTarget.classList.add('hidden-box');
    }

    if (type == 'independent_contractor') {
      addIndependentContractorButton.classList.remove('hidden')
    } else {
      addIndependentContractorButton.classList.add('hidden')
    }

    if (type == 'lease' || type == 'rent') {
      addLeaseRentCompanyButton.classList.remove('hidden');
    } else {
      addLeaseRentCompanyButton.classList.add('hidden');
    }

    controller.leaseRentFieldTargets.forEach((item, i) => {
      if (type == 'lease' || type == 'rent') {
        item.classList.remove('hidden');
        addLeaseRentCompanyButton.classList.remove('hidden');
      } else {
        item.classList.add('hidden');
        addLeaseRentCompanyButton.classList.add('hidden');
      }
    });

    controller.leaseFieldTargets.forEach((item, i) => {
      if (type == 'lease') {
        item.classList.remove('hidden');
      } else {
        item.classList.add('hidden');
      }
    });

    if (controller.hasSubleaseLeaseFieldTarget) {
      controller.subleaseLeaseFieldTargets.forEach((item, i) => {
        if (type == 'company') {
          item.classList.remove('hidden');
        } else {
          item.classList.add('hidden');
        }
      });
    }
  }

  onChange(element) {
    this.setVisibleFields()
  }

  setMileageFields(element) {
    let fieldsForm = element.closest('.ownership-box');
    let fields = fieldsForm.querySelectorAll("[data-ownership-target='mileageFields']");

    fields.forEach((item, i) => {
      if (element.checked) {
        item.disabled = false;
      } else {
        item.disabled = true;
      }
    });
  }

  onMileageChange(element) {
    this.setMileageFields(element.currentTarget);
  }

  setOwnerOptions(element) {
    let controller = this
    let dData =  new FormData();
    dData.set('type', element.currentTarget.value);
    dData.set('value', controller.currentOwnerId);
    dData.set('object_name', element.currentTarget.dataset.objectName);

    post('/business_associate_options', {
      query: dData,
      responseKind: 'turbo-stream'
    })
  }
}
