import { Controller } from '@hotwired/stimulus'
// import IMask from 'imask'
import Inputmask from 'inputmask'

export default class extends Controller {
  static targets = ['phone', 'ssn', 'date', 'integer']

  phoneTargetConnected(target) {
    // this.mask = IMask(this.phoneTarget, {
    //   mask: '(000) 000-0000',
    //   // pattern: '(000) 000-0000',
    //   // lazy: false
    // });

    // this.mask = Inputmask({
    //   mask: '(999) 999-9999',
    // }).mask(this.phoneTarget)
  }

  ssnTargetConnected(target) {
    this.mask = Inputmask({
      mask: '999-99-9999',
    }).mask(this.ssnTarget)
  }

  integerTargetConnected(target) {
    this.mask = Inputmask('integer', {}).mask(this.integerTarget)
  }

  decimalTargetConnected(target) {
    this.mask = Inputmask('decimal', {}).mask(target)
  }
}
