import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['insuranceType', 'expirationDate']

  connect() {
    if (this.hasExpirationDateTarget && this.hasInsuranceTypeTarget) {
      this.expirationDateVisibility()
      this.insuranceTypeTarget.addEventListener('change', (event) => {
        this.expirationDateVisibility()
      });
    }
  }

  expirationDateVisibility() {
    let field = this.expirationDateTarget.closest('.field')
    let value = this.insuranceTypeTarget.options[this.insuranceTypeTarget.selectedIndex].value;
    if (value == 'vendor_policy') {
      field.classList.add('hidden');
      this.expirationDateTarget.value = '';
    } else {
      field.classList.remove('hidden');
    }
  }
}
