import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['ownInsurance', 'field']

  connect() {
    this.setVisibleFields()
  }

  setVisibleFields() {
    const controller = this
    const ownInsurance = controller.ownInsuranceTarget.checked
    controller.fieldTargets.forEach((item, i) => {
      let value = item.dataset.value
      let fieldType = item.dataset.ownInsurance
      let field = item.closest('.field')
      if (ownInsurance) {
        if (fieldType == 'true') {
          field.classList.remove('hidden');
        } else {
          field.classList.add('hidden');
        }
      } else {
        if (fieldType == 'true') {
          field.classList.add('hidden');
        } else {
          field.classList.remove('hidden');
        }
      }
    });
  }

  onChange(event) {
    this.setVisibleFields()
  }
}
