import { Controller } from '@hotwired/stimulus'
import SlimSelect from 'slim-select'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['fieldWithTooltip', 'selectBox',
                    'fieldWithOptions', 'multiselect', 'field']

  connect() {}

  fieldTargetConnected(target) {
    let arrayOptions = Array.from(target.options)
    let placeholder = arrayOptions.filter(item => item.value === '')
    let allowDeselect = (placeholder.length > 0)
    let sSelect = new SlimSelect({select: target, selected: true, allowDeselect: allowDeselect, addToBody: true })
    if (target.hasAttribute('readonly')) { sSelect.disable() }
  }

  fieldTargetDisconnected(target) {
    target.slim.destroy()
  }

  fieldWithOptionsTargetConnected(target) {
    let options = null;
    let data = [];
    options = target.options;
    let placeholder = ''
    options.forEach((item) => {
      let disabled = (item.dataset.notSelectable == 'true') ? true : false;
      if (item.value == "") { placeholder = item.text }
      let innerHTML = '';
      if (item.dataset.warnings) {
        let warnings = item.dataset.warnings.split(';');
        warnings.forEach((item) => {
          innerHTML += item;
        });
      }
      if (item.dataset.html) {
        innerHTML += item.dataset.html
      }
      delete item.dataset.warnings;
      delete item.dataset.html;
      if (item.value == "") {
        data.push({
          value: '',
          text: '',
          data: { placeholder: true }
        })
      } else {
        data.push({
          value: item.value,
          text: item.text,
          disabled: disabled,
          innerHTML: item.text + innerHTML,
          selected: item.selected,
          data: item.dataset
        })
      }
    })
    let sSelect = new SlimSelect({ placeholder: placeholder, select: target,
      selected: true, data: data, addToBody: true, allowDeselect: target.dataset.allowDeselect === 'true' })
    if (target.hasAttribute('readonly')) { sSelect.disable() }
  }

  fieldWithOptionsTargetDisconnected(target) {
    target.slim.destroy()
  }

  multiselectTargetConnected(target) {
    new SlimSelect({select: target, selected: true, showSearch: false, addToBody: true })
  }

  multiselectTargetDisconnected(target) {
    target.slim.destroy()
  }

  fieldWithTooltipTargetConnected(target) {
    let options = null
    let data = []
    options = target.options;
    options.forEach((item) => {
      data.push({
        value: item.value,
        text: item.text,
        innerHTML: "<span class='tooltip', data-microtip-size='large' data-microtip-position='bootom-right' role='tooltip' aria-label='" + item.dataset.title + "'>" + item.text + "</div>",
        selected: item.selected,
        data: item.dataset
      })
    })
    let sSelect = new SlimSelect({ select: target, selected: true, showSearch: false, data: data, addToBody: true })
    if (target.hasAttribute('readonly')) { sSelect.disable() }
  }

  fieldWithTooltipTargetDisconnected(target) {
    target.slim.destroy()
  }

  selectBoxTargetConnected(element) {
    new TomSelect(element, {
      plugins: ['remove_button'],
      create: false,
      hidePlaceholder: true,
      maxOptions: null,
      render: {
        option: function(data, escape) {
          let html = `<div>${escape(data.text)}</div>`;
          if (data.html) {
            html = `<div>${escape(data.text)}${data.html}</div>`;
          }
          return html;
        }
      }
    });
  }

  // selectBoxTargetDisconnected(element) {
  //   // element.tomselect
  // }
}
