import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'input', 'target']

  add({ target }) {
    const select = target
    const option = target.options[target.selectedIndex]

    this._renderSelectedInput(option)

    this._disableSelectedFilter(option)

    this._resetSelectToPrompt(target)
  }

  remove({ target }) {
    const linkElement = target.tagName == 'A' ? target : target.parentNode;

    this._removeInput(this.element, linkElement.dataset.target)

    this._enableRemovedFilterOption(this.selectTarget, linkElement.dataset.target)
  }

  inputTargetConnected(el) {
    const inputName = el.dataset.inputName
    const option = Array.from(this.selectTarget.options).find((o) => o.value === inputName)

    this._disableSelectedFilter(option)

    this._resetSelectToPrompt(this.selectTarget)
  }

  _renderSelectedInput(option) {
    const template = document.querySelector(`template[data-name="${option.value}"]`)
    this.targetTarget.append(template.content.cloneNode(true))
  }

  _disableSelectedFilter(option) {
    option.disabled = true
  }

  _resetSelectToPrompt(select) {
    select.selectedIndex = 0
  }

  _removeInput(form, name) {
    const wrapper = form.querySelector(`[data-wrapper-for="${name}"]`)
    if (!wrapper) { return }

    wrapper.remove()
  }

  _enableRemovedFilterOption(options, name) {
    const option = Array.from(options).find((o) => o.value === name)
    if (!option) { return }

    option.disabled = false
  }
}
