import { Controller } from '@hotwired/stimulus'
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['form', 'scrollable', 'submitButton', 'content', 'textarea', 'notes', 'history' ]
  static values = { url: String, notesUrl: String, historyUrl: String };

  connect() {}

  // scrollableTargetConnected() {
  //   this.scrollableTarget.scrollTop = this.scrollableTarget.scrollHeight;
  // }

  contentTargetConnected() {
    const controller = this
    fetch(controller.urlValue)
    .then((r) => r.text())
    .then((html) => {
      controller.contentTarget.innerHTML = html;
    })
    .then((response) => {
      controller.contentScrollToBottom()
    })
  }

  notesTargetConnected() {
    const controller = this
    fetch(controller.notesUrlValue)
    .then((r) => r.text())
    .then((html) => {
      controller.notesTarget.innerHTML = html;
    })
    .then((response) => {
      controller.contentScrollToBottom()
    })
  }

  historyTargetConnected() {
    const controller = this
    fetch(controller.historyUrlValue)
    .then((r) => r.text())
    .then((html) => {
      controller.historyTarget.innerHTML = html;
    })
    .then((response) => {
      controller.contentScrollToBottom()
    })
  }

  textareaTargetConnected() {
    const controller = this
    this.textareaTarget.addEventListener('keydown', function(event) {
      if (event.keyCode === 13 && !event.ctrlKey) {
        event.preventDefault();
        Rails.fire(controller.formTarget, 'submit');
      } else if (event.keyCode === 13 && event.ctrlKey) {
        event.preventDefault();
        controller.textareaTarget.value += '\n';
        controller.textareaTarget.scrollTop = controller.textareaTarget.scrollHeight;
      }
    });
  }

  submit(event) {
    if (event.detail.success) {
      this.contentScrollToBottom()
    }
  }

  contentScrollToBottom() {
    this.scrollableTargets.forEach(element => {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth'
      });
    });
  }

  enableSubmit(event) {
    if (event.currentTarget.value.length > 0) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }
}