import { Controller } from '@hotwired/stimulus';

import { isNil, toNumber, isNumber, isNaN } from 'lodash'

export default class extends Controller {
  static targets = ['rateType', 'rateField', 'cost', 'total', 'chargesHeader', 'calculatonColumn',
                    'customerMiles', 'isFscPercentage', 'fsc', 'rpm', 'linehaul'];

  connect() {
    const controller = this
    this.setFieldsVisibility()
    if (this.hasRateTypeTarget) { this.rateFieldSetReadonly() }
  }
  customerMilesConnected(target) {
    const controller = this
    target.addEventListener.addEventListener('change', (event) => {
      controller.setRateValue();
    });
  }
  costTargetConnected() {
    this.chargesHeaderTarget.classList.remove('hidden')
  }

  rateFieldTargetConnected(target) {
    const controller = this
    target.addEventListener('change', (event) => {
      controller.setTotal();
    });
  }

  rateTypeTargetConnected(target) {
    const controller = this
    target.addEventListener('change', (event) => {
      controller.onRateTypeChange();
    });
  }
  setFieldsVisibility() {
    let lhlFields = this.element.querySelectorAll("[data-field='linehaulField']")
    let rpmFields = this.element.querySelectorAll("[data-field='rpmField']")
    if (!this.hasRateTypeTarget) { return }
    if (this.rateTypeTarget.value == 'linehaul') {
      lhlFields.forEach(field => { field.classList.remove('hidden')});
      rpmFields.forEach(field => { field.classList.add('hidden')});
      this.calculatonColumnTarget.classList.remove('xs')
    } else if (this.rateTypeTarget.value == 'rpm') {
      lhlFields.forEach(field => { field.classList.add('hidden')});
      rpmFields.forEach(field => { field.classList.remove('hidden')});
      this.calculatonColumnTarget.classList.add('xs')
    } else {
      lhlFields.forEach(field => { field.classList.add('hidden')});
      rpmFields.forEach(field => { field.classList.add('hidden')});
      this.calculatonColumnTarget.classList.add('xs')
    }
  }

  onRateTypeChange(event) {
    this.setFieldsVisibility()
    this.rateFieldSetReadonly()
  }

  rateFieldSetReadonly() {
    if (this.rateFieldTarget.readOnly) return
    if ((this.rateTypeTarget.value == 'linehaul') || (this.rateTypeTarget.value == 'rpm')) {
      this.rateFieldTarget.readOnly = true;
    } else {
      this.rateFieldTarget.readOnly = false;
    }
  }

  setRateValue() {
    let rate = 0
    if (this.rateTypeTarget.value == 'linehaul') {
      let linehaulValue = this.linehaulTarget.value
      let fscValue = this.fscTarget.value
      if (this.isFscPercentageTarget.checked) {
        rate = _.toNumber(linehaulValue) + ( _.toNumber(linehaulValue) * (_.toNumber(fscValue) / 100))
      } else {
        rate = _.toNumber(linehaulValue) + _.toNumber(fscValue)
      }
      this.rateFieldTarget.value = rate
    } else if (this.rateTypeTarget.value == 'rpm') {
      let customerMilesValue = this.customerMilesTarget.value
      let rpmValue = this.rpmTarget.value
      rate = _.toNumber(customerMilesValue) * _.toNumber(rpmValue)
      this.rateFieldTarget.value = rate
    }
    this.rateFieldTarget.dispatchEvent(new Event('change'));
  }

  setTotal() {
    if (!this.hasTotalTarget) { return }
    let total = 0
    let rateValue = this.hasRateTypeTarget ? this.rateFieldTarget.value : 0
    total = _.toNumber(rateValue)
    Array.from(this.costTargets).forEach(input => {
      let nestedForm = input.closest('.nested-form-wrapper')
      let destroyField = nestedForm.querySelector('[id$="_destroy"]')
      if (destroyField.value != 'false') { return; }
      total += _.toNumber(input.value)
    })
    this.totalTarget.value = total
  }
}
