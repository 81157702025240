// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as $ from 'jquery/dist/jquery'
import * as Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import 'controllers';

import { Common } from './src/common';
import { Turbo } from '@hotwired/turbo-rails';
import { createConsumer } from "@rails/actioncable"
import "chartkick/chart.js"

Rails.start()
ActiveStorage.start()

Common.initialize();
createConsumer()

Turbo.start()
Turbo.setProgressBarDelay(50)

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

document.addEventListener("turbo:load", function(event) {
  if (!event.detail) return

  if (!/\/dispatch_calendars$/.test(event.detail.url)) {
    localStorage.removeItem('calendarStartAt');
  }
})

document.addEventListener("turbo:frame-load", function (event) {
  let firstFormField = event.target.querySelector('input:not([type="hidden"]), select, textarea, .button')
  if (firstFormField) {
    firstFormField.setAttribute('tabindex', '0');
    firstFormField.focus();
  }
})
// export const getTurbo = async () => {
//   if (!window.Turbo) {
//     const Turbo = require('@hotwired/turbo')
//     await Turbo.start()
//     window.Turbo = Turbo
//   }
//   return window.Turbo
// }
