import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];

  toggleContent(event) {
    const controller = this
    let button = event.currentTarget
    let icon = button.querySelector('i.indicator.icon');
    if (controller.hasContentTarget) {
      controller.contentTarget.classList.toggle('hidden')
      if (controller.contentTarget.classList.contains('hidden')) {
        button.setAttribute('aria-label', 'Expand');
        icon.classList.remove('expanded')
        icon.classList.add('collapsed')
      } else {
        button.setAttribute('aria-label', 'Collapse');
        icon.classList.remove('collapsed')
        icon.classList.add('expanded')
      }
    }
  }

  disconnect() {}
}
