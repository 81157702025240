import { Controller } from '@hotwired/stimulus';
import { getAbsoluteOffsetTop, getAbsoluteOffsetLeft } from "helpers";
import Rails from "@rails/ujs";
import { addDialog, getLast, removeDialog } from '../helpers/dialog_registry';
import { showOverlay, hideOverlay } from '../helpers/overlays';

export default class extends Controller {
  static targets = ['form', 'dialogBox']
  loader;
  connect() {
    this.loader = document.querySelector('.page-loader');
  }

  formTargetConnected(target) {
    const controller = this
    target.addEventListener('turbo:submit-end', (event)  => {
      controller.submit(event)
    });
  }

  submit(event) {
    if (event.detail.success) {
      const box = event.currentTarget.closest('.overlay-content-wrapper')
      const boxId = box.id
      const frameId = box.dataset.frameId;
      const frame = document.querySelector("turbo-frame#" + frameId);
      this.hide(boxId);
      frame.src = ''
      if (frameId == 'shippers') {
        let showOverlay = event.detail.formSubmission.formElement.querySelector('#shipper_create_receiver')
        if (showOverlay && showOverlay.checked) {
          showOverlay('receiver')
        }
      } else if (frameId == 'receivers') {
        let showOverlay = event.detail.formSubmission.formElement.querySelector('#receiver_create_shipper')
        if (showOverlay && showOverlay.checked) {
          showOverlay('shipper')
        }
      }
    } else {
      this.loader.style.display = 'none';
    }
  }

  inlineSubmit(event) {
    if (event.detail.success) {
      const box = event.currentTarget.closest('.overlay-content-wrapper')
      const boxId = box.id
      const frameId = box.dataset.frameId;
      const frame = document.querySelector(`turbo-frame#${frameId}`);
      frame.src = ''
    } else {
      this.loader.style.display = 'none';
    }
  }

  showInlineForm(event) {
    if (event.ctrlKey) {
      return false;
    }
    const boxId = event.currentTarget.dataset['boxId']
    const box = document.getElementById(`overlay_${boxId}_dialog`);
    const alertBox = document.querySelector('.alert-error');
    const documentsWrapper = document.getElementById('documents_wrapper');

    if (alertBox != null) {
      alertBox.remove();
    }
    let td = event.currentTarget.closest('td')
    let offsetTop = getAbsoluteOffsetTop(td)
    let offsetLeft = getAbsoluteOffsetLeft(td)
    let form = box.querySelector('.overlay-box')
    form.style.top = `${offsetTop}px`
    form.style.left = `${offsetLeft}px`
    form.style.width = `${td.offsetWidth}px`
    box.classList.add('overlay-show');
    box.classList.remove('overlay-hide');
    addDialog(`overlay_${boxId}_dialog`, 'inline')
  }

  inlineSubmitForm() {
    Rails.fire(this.formTarget, 'submit');
  }

  closeInline(event) {
    event.preventDefault()
    const box = event.currentTarget.closest('.overlay-content-wrapper')
    this.hideInline(box.id)
  }

  hideInline(boxId) {
    const box = document.getElementById(boxId);
    box.classList.remove('overlay-show');
    box.classList.add('overlay-hide');
    if (box) {
      const frame = document.querySelector(`turbo-frame#${box.dataset.frameId}`);
      frame.src = '';
    }
    removeDialog(box.id)
  }

  disconnect() {}

  close(event) {
    event.preventDefault()
    const box = event.currentTarget.closest('.overlay-content-wrapper')
    const boxId = box.id
    this.hide(boxId);
    if (box) {
      const frameId = box.dataset.frameId;
      const frame = document.querySelector("turbo-frame#" + frameId);
      frame.src = '';
    }
  }

  show(event) {
    const controller = this
    if (event.ctrlKey) {
      return false;
    }
    const boxId = event.currentTarget.dataset['boxId']
    const box = document.getElementById(`overlay_${boxId}_dialog`);
    const alertBox = document.querySelector('.alert-error');
    const documentsWrapper = document.getElementById('documents_wrapper');
    const batchActionCloseButton = document.querySelector('.batch-action-bar a.close-button')
    addDialog(`overlay_${boxId}_dialog`)
    if (alertBox != null) { alertBox.remove(); }
    showOverlay(boxId)
    if (batchActionCloseButton != null) { batchActionCloseButton.click() }
    if (event.currentTarget.dataset.formType != 'fromDialog') { return }
    if (documentsWrapper && documentsWrapper.classList.contains('visible')) {
      box.classList.add('with-preview')
    } else {
      box.classList.remove('with-preview')
    }
  };

  dialogBoxTargetConnected(element) {
    const controller = this

    // element.addEventListener('keydown', (event) => {
    //   if (event.key === 'Escape') {
    //     if (getLast()) {
    //       if (getLast().type == 'overlay') {
    //         controller.hide(getLast().id)
    //       } else if (getLast().type == 'inline') {
    //         controller.hideInline(getLast().id)
    //       }
    //       event.stopPropagation()
    //     }
    //   }
    // })
  }

  hide(boxId) {
    hideOverlay(boxId)
    removeDialog(boxId)
  }
}
