import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['type', 'ssn', 'llc', 'llcField', 'label',
                    'driver', 'driverField', 'contactFields', 'customContactAdd']
  static values = { urlDriver: String }

  connect() {
    this.setFields()
  }

  driverFieldTargetConnected(target) {
    let controller = this
    const driverId = target.value
    const disabled = (driverId != '')
    const inputs = this.driversFields()
    disabled ? this.customContactAddTarget.classList.add('hidden') : this.customContactAddTarget.classList.remove('hidden')
    inputs.forEach((input) => {
      input.disabled = disabled
      if (input.slim) {
        disabled ? field.slim.disable() : field.slim.enable()
      }
    })
  }

  setFields() {
    const controller = this
    const type = controller.typeTarget.value
    if (type == 'company') {
      controller.labelTarget.textContent = 'Company Name'
      controller.llcTarget.classList.remove('hidden')
      controller.ssnTarget.classList.add('hidden')
      controller.driverTarget.classList.add('hidden')
    } else {
      controller.labelTarget.textContent = 'Personal Name'
      controller.llcTarget.classList.add('hidden')
      controller.ssnTarget.classList.remove('hidden')
      controller.driverTarget.classList.remove('hidden')
    }
  }

  onChange(event) {
    this.setFields()
    const controller = this
    if (controller.typeTarget.value == 'company') {
      controller.driverFieldTarget.value = ''
    } else {
      controller.llcFieldTarget.value = ''
    }
    controller.driverFieldTarget.dispatchEvent(new Event('change'));
  }

  onDriverChange(event) {
    const controller = this
    const driverId = event.currentTarget.value
    const disabled = (driverId != '')
    const inputs = this.driversFields()
    disabled ? this.customContactAddTarget.classList.add('hidden') : this.customContactAddTarget.classList.remove('hidden')
    Array.from(inputs).forEach((input) => {
      controller.disableDriversField(input, disabled)
    })
    if (disabled) {
      const url = new URL(this.urlDriverValue)
      url.searchParams.append('driver_id', driverId)
      fetch(url)
        .then(response => response.json())
        .then(result => {
          controller.setDriverData(result)
        })
    }
  }

  setDriverData(driver) {
    const controller = this
    let fields = controller.element.querySelectorAll('input, select')
    fields.forEach(field => {
      if (field.dataset.address) {
        field.value = driver.address[field.dataset.address] || ''
        if (field.slim) { field.slim.set(driver.address[field.dataset.address]) }
      } else if (field.dataset.driver) {
        field.value = driver[field.dataset.driver] || ''
        if (field.slim) { field.slim.set(driver[field.dataset.driver]) }
      } else if (field.dataset.contact) {
        field.value = driver[field.dataset.contact] || ''
        if (field.slim) { field.slim.set(driver[field.dataset.contact]) }
      }
    });
  }

  disableDriversField(field, disabled) {
    field.disabled = disabled
    if (field.slim) {
      disabled ? field.slim.disable() : field.slim.enable()
    }
    if (!disabled) { this.resetDriversFieldValue(field) }
  }

  driversFields() {
    let fields = this.element.querySelectorAll('[data-driver], [data-address]');
    let contactsFields = this.contactFieldsTarget.querySelectorAll('input, select');
    return Array.from(fields).concat(Array.from(contactsFields))
  }

  resetDriversFieldValue(field) {
    if (field.tagName == 'SELECT') {
      field.selectedIndex = 0
    } else {
      field.value = ""
    }
  }
}
