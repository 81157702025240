import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['associationForm' ]

  connect() {
  }

  onChangeStatus(event) {
    let status = event.currentTarget.value;
    this.associationFormTargets.forEach((item) => {
      if (status == 'subunit') {
        item.classList.add('hidden-box');
      } else {
        item.classList.remove('hidden-box');
      }
    });
  }
}
