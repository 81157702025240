import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['driverSelectField', 'driversBox']
  static values = { alertUrl: String }

  setDriversValue = true;
  setPowerUnitValue = true

  connect() {
    const controller = this
  }

  driverSelectFieldTargetConnected(element) {
    if (element.dataset.loaded == 'false') {
      this.setSelectOptions(element);
    }
  }

  async setSelectOptions(element) {
    let controller = this
    let dData =  new FormData();
    dData.set('type', element.dataset.type);
    dData.set('index', element.dataset.index);
    dData.set('target', element.dataset.target);
    let selectedId = element.dataset.selected ? element.dataset.selected : ''
    dData.set('selected_id', selectedId);

    const response = await post('/truck_loads/load_select_options', {
      query: dData,
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      element.dataset.loaded = 'true'
    }
  }

  onChangeDriver(event) {
    const controller = this
    controller.setDriversValue = false;
    let driversFields = controller.driversFields();
    const currentDriver = event.currentTarget
    let powerUnitsFields = this.driversBoxTarget.querySelectorAll('[name$="[power_unit_id]"]');

    if (controller.setPowerUnitValue) {
      let idx = currentDriver.selectedIndex;
      if (idx == -1) { return }
      let powerUnit = event.currentTarget.options[idx].dataset.powerUnit || '';
      let subunit = event.currentTarget.options[idx].dataset.subunit;

      powerUnitsFields[0].value = powerUnit;
      powerUnitsFields[0].slim.set(powerUnit);

      let drivers = ''
      if (powerUnitsFields[0].selectedIndex != -1) {
        drivers = powerUnitsFields[0].options[powerUnitsFields[0].selectedIndex].dataset.drivers || '';
      }
      if (drivers) {
        let driversIds = drivers.split(',');
        if ((driversIds.length > 1) && (driversFields.length < 2)) {
          controller.attachDriver()
        }
        if ((driversIds.length < 2) && (driversFields.length == 2)) {
          driversIds.push('')
        }
        driversFields = controller.driversFields();

        let currentDriver = event.currentTarget
        let currentDriverId = currentDriver.value
        driversIds = driversIds.filter(item => item !== currentDriverId)
        controller.setPowerUnitValue = false;
        driversFields.forEach(field => {
          if (field !== currentDriver) {
            field.value = driversIds[0]
            if (field.slim) { field.slim.set(driversIds[0]) }
            if (driversIds[0] === "") { controller.destroyDriver(field.name); }
            controller.setDriversWarnings(field)
          }
        })
      } else {
        driversFields.forEach(field => {
          if (field !== currentDriver) {
            field.value = ''
            if (field.slim) { field.slim.set('') }
            controller.destroyDriver(field.name);
            controller.setDriversWarnings(field)
          }
        });
      }
      controller.setPowerUnitValue = true;
      controller.setSubunit(subunit);
    }
    controller.setDriversValue = true;
    if (currentDriver.value == '') {
      driversFields.forEach(field => {
        controller.destroyDriver(field.name);
        controller.setDriversWarnings(field)
      })
      controller.destroyUnit(powerUnitsFields[0].name);
    }
  }

  setSubunit(subunit) {
    let subunitsFields = this.driversBoxTarget.querySelectorAll('.power-unit-subunits');
    let warnningInnerHTML = '';
    if (subunit) {
      if (subunitsFields.length == 0) {
        document.getElementById('attach_subunit').click();
      }
      subunitsFields = this.driversBoxTarget.querySelectorAll('.power-unit-subunits');
      let subunitField = subunitsFields[0].querySelector('select');
      let isSubunitField = subunitsFields[0].querySelector('[name$="[is_subunit]"]');
      let subunitIdField = subunitsFields[0].querySelector('[name$="[power_unit_id]"]');
      subunitField.value = subunit
      isSubunitField.value = true;
      subunitIdField.value = subunit;
      let idx = subunitField.options.selectedIndex;
      let warningsDataset = subunitField.options[idx].dataset.warnings;
      let warningHtml = subunitsFields[0].querySelector('.warnings')
      if (warningsDataset) {
        warnningInnerHTML = this.expirationDatesWarnings(warningsDataset);
      }
      warningHtml.innerHTML = warnningInnerHTML;
    } else {
      if (subunitsFields.length > 0) {
        let subunitField = subunitsFields[0].querySelector('select');
        let destroySubunitField = subunitsFields[0].querySelector('[name$="[_destroy]"]');
        let subunitIdField = subunitsFields[0].querySelector('[name$="[power_unit_id]"]');
        let warningHtml = subunitsFields[0].querySelector('.warnings')
        warningHtml.innerHTML = ''
        subunitField.value = null
        destroySubunitField.value = true;
        subunitIdField.value = null
      }
    }
  }

  setTrailer(event) {
    let idx = event.currentTarget.selectedIndex;
    if (idx == -1) { return }

    let fieldWrapper = document.querySelector('.truck-load-trailer');
    let trailer = event.currentTarget.options[idx].dataset.trailer || "";
    let trailerFields = document.querySelectorAll('[name^="truck_load[truck_load_trailers_attributes]["][name$="[trailer]"]');
    let trailerFieldId = document.querySelector('[name^="truck_load[truck_load_trailers_attributes]["][name$="[trailer_id]"]');
    trailerFields[0].value = trailer;
    trailerFieldId.value = trailer;

    let warnningInnerHTML = '';
    let warningHtml = fieldWrapper.querySelector('.warnings');
    let warningsDataset = trailerFields[0].options[trailerFields[0].selectedIndex].dataset.warnings;
    if (warningsDataset) {
      warnningInnerHTML = this.expirationDatesWarnings(warningsDataset);
    }
    warningHtml.innerHTML = warnningInnerHTML;
    if (event.currentTarget.value == '') {
      this.destroyTrailer(trailerFields[0].name);
    }
  }

  onChangePowerUnit(event) {
    const controller = this

    controller.setPowerUnitValue = false;
    if (controller.setDriversValue) {
      let idx = event.currentTarget.selectedIndex;
      let drivers = event.currentTarget.options[idx].dataset.drivers || '';
      let subunit = event.currentTarget.options[idx].dataset.subunit;
      let driversFields = controller.driversFields()

      let driversIds = drivers.split(',');

      if ((driversIds.length > 1) && (driversFields.length < 2)) {
        controller.attachDriver()
      }
      if ((driversIds.length < 2) && (driversFields.length == 2)) {
        driversIds.push('')
      }
      driversFields = controller.driversFields()

      driversIds.forEach((id, i) => {
        driversFields[i].value = id;
        if (driversFields[i].slim) { driversFields[i].slim.set(id) }
        if (id == "") { controller.destroyDriver(driversFields[i].name) }
        controller.setDriversWarnings(driversFields[i])
      });
      controller.setSubunit(subunit);
    }
    controller.setPowerUnitValue = true;

  }

  attachDriver() {
    let button = document.getElementById('attach_driver');
    button.click();
  }

  driversFields() {
    return this.driversBoxTarget.querySelectorAll('[name^="truck_load[truck_load_drivers_attributes]["][name$="[driver_id]"]');
  }

  setDriversWarnings(field, value) {
    let fieldWrapper = field.closest('.field');
    let warningHtml = fieldWrapper.querySelector('.warnings');
    let warnningInnerHTML = ''
    if (warningHtml) {
      let idx = field.options.selectedIndex;
      if (idx != -1) {
        let warningsDataset = field.options[idx].dataset.warnings;
        warnningInnerHTML = this.expirationDatesWarnings(warningsDataset);
      }
      warningHtml.innerHTML = warnningInnerHTML;
    }
  }

  destroyDriver(fieldName) {
    const regexp = new RegExp(/truck_load\[truck_load_drivers_attributes\]\[(.+)\]\[driver_id\]/ig);
    const match = regexp.exec(fieldName);

    let destroyField = document.getElementsByName('truck_load[truck_load_drivers_attributes][' + match[1] + '][_destroy]');
    destroyField[0].value = true;
  }
  destroyUnit(fieldName) {
    const regexp = new RegExp(/truck_load\[truck_load_power_units_attributes\]\[(.+)\]\[power_unit_id\]/ig);
    const match = regexp.exec(fieldName);

    let destroyField = document.getElementsByName('truck_load[truck_load_power_units_attributes][' + match[1] + '][_destroy]');
    destroyField[0].value = true;
  }
  destroyTrailer(fieldName) {
    const regexp = new RegExp(/truck_load\[truck_load_trailers_attributes\]\[(.+)\]\[trailer\]/ig);
    const match = regexp.exec(fieldName);

    let destroyField = document.getElementsByName('truck_load[truck_load_trailers_attributes][' + match[1] + '][_destroy]');
    destroyField[0].value = true;
  }

  expirationDatesWarnings(warningsDataset) {
    let warnningInnerHTML = '';
    if (warningsDataset) {
      let warnings = warningsDataset.split(';');
      warnings.forEach((item) => {
        warnningInnerHTML += item;
      });
    }

    return warnningInnerHTML;
  }

  fetchExpirationDatesAlert(event) {
    const controller = this
    const url = new URL(controller.alertUrlValue)

    let dData =  new FormData();
    if (!event.currentTarget.value) { return }

    dData.set('power_unit_id', event.currentTarget.value);
    const response = post(controller.alertUrlValue, {
      query: dData,
      responseKind: 'turbo-stream'
    })
  }
}
