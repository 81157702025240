import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['type', 'dispatcherTeam']

  connect() {
    this.setFields()
  }

  setFields() {
    const controller = this
    const type = controller.typeTarget.value
    if (type != 'otr') {
      controller.dispatcherTeamTarget.classList.add('hidden')
      let dispatcherTeam = document.getElementById('account_user_attributes_dispatcher_team_id')
      if (dispatcherTeam.slim) { dispatcherTeam.slim.set('') }
      dispatcherTeam.value = ''
    } else {
      controller.dispatcherTeamTarget.classList.remove('hidden')
    }
  }

  onChange(event) {
    this.setFields()
  }
}
