import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'billingAddress', 'mailingAddress']

  connect() {
  }

  onChangeAsMailing(event) {
    let bDisabled = (event.currentTarget.checked) ? true : false

    let inputs = this.billingAddressTarget.getElementsByTagName("input");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].disabled = bDisabled;
    }
    let selects = this.billingAddressTarget.getElementsByTagName("select");
    for (var i = 0; i < selects.length; i++) {
      selects[i].disabled = bDisabled;
      if (selects[i].slim) {
        bDisabled ? selects[i].slim.disable() : selects[i].slim.enable()
      }
    }
    if (bDisabled == true) {
      this.setAddressToBillingAddress();
    }
  }

  setAddressToBillingAddress() {
    const controller = this
    let addressInputs = controller.mailingAddressTarget.getElementsByTagName("input");
    let addressSelects = this.mailingAddressTarget.getElementsByTagName("select");

    addressInputs.forEach(field => {
      let value = field.value
      if (field.dataset.address) {
        let target = controller.billingAddressTarget.querySelector("[data-address='" + field.dataset.address + "']")
        target.value = value
      }
    });

    addressSelects.forEach(field => {
      let value = field.value
      if (field.dataset.address) {
        let target = controller.billingAddressTarget.querySelector("[data-address='" + field.dataset.address + "']")
        target.value = value
        if (target.slim) {
          target.slim.set(value)
        }
      }
    });
  }
}
