import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'form', 'query', 'field', 'filterWrapper' ]
  timer;
  previousQuery;

  connect() {

  }

  fieldTargetConnected(element) {
    const controller = this;
    element.addEventListener('change', (event) => {
      controller.load();
    });
  }

  queryTargetConnected(element) {
    const controller = this;

    controller.timer = null;
    controller.previousQuery = '';
    if ((element.value.length > 0) && (!element.dataset.hasOwnProperty('inputName'))) {
      let quickSearch = document.getElementById('quick_search')
      if (quickSearch) quickSearch.value = element.value
    }
    element.addEventListener('input', (event) => {
      controller.search(element);
    });
  }

  filterWrapperTargetDisconnected(element) {
    const controller = this;

    controller.load();
  }

  load() {
    const controller = this;
    const query = new FormData(controller.formTarget)
    query.set('search', true)

    const urlString = new URLSearchParams(query)
    urlString.toString()

    let url = new URL(controller.formTarget.action)
    url.search = urlString

    get(url.toString(), {
      responseKind: 'turbo-stream'
    })
  }

  search(queryTarget) {
    const controller = this

    if (queryTarget.value == controller.previousQuery) { return }
    controller.previousQuery = queryTarget.value
    if (controller.timer != null) {
      clearTimeout(controller.timer);
    }
    controller.timer = setTimeout(()=> {
      controller.load()
    }, 500)
  }

  quickSearch(event) {
    const controller = this
    controller.queryTarget.value = event.currentTarget.value;
    controller.queryTarget.dispatchEvent(new Event('input'));
  }
}
