import { Controller } from '@hotwired/stimulus'
import * as Rails from '@rails/ujs';
import { insertGoogleMap } from '../helpers/maps';

export default class extends Controller {
  map;
  marker;
  google;
  origLatitude;
  origLongitude;
  origZoom;
  newLatitude;
  newLongitude;
  autocomplete;

  static targets = ['map', 'latitude', 'longitude', 'coordinatesVerifiedFlag',
    'verifyCoordinatesWrapper', 'geocoded', 'coordinatesVerified', 'skipVerification',
    'geocoderErrorMessage', 'address1Field', 'address2Field', 'address3Field', 'cityField',
    'zipCodeField', 'stateField', 'countryField', 'setAutocomplete', 'fillInAddress', 'timeZone']
  static values = { mapKey: String }

  connect() {
    let controller = this;

    if (controller.hasVerifyCoordinatesWrapperTarget) {
      controller.setVerifyCoordinates()
    }
    if (typeof (google) == 'undefined') {
      insertGoogleMap(controller.mapKeyValue)
    } else {
      controller.setMap();
      if (controller.hasSetAutocompleteTarget) {
        controller.setAutocomplete()
      }
    }
  }

  disableEnterKey(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  }
  setVerifyCoordinates() {
    this.verifyCoordinatesWrapperTarget.style.visibility = "hidden";
    this.coordinatesVerifiedFlagTarget.style.visibility = "hidden";
    if (this.latitudeTarget.value != "" && this.longitudeTarget.value != "") {
      this.origLatitude = this.latitudeTarget.value
      this.origLongitude = this.longitudeTarget.value
      this.origZoom = 14
      if (this.coordinatesVerifiedTarget.value == 'false' || this.skipVerificationTarget.checked) {
        this.verifyCoordinatesWrapperTarget.style.visibility = "";
      } else {
        this.coordinatesVerifiedFlagTarget.style.visibility = "";
      }
    } else {
      this.origLatitude = 38
      this.origLongitude = -96
      this.origZoom = 4
    }
  }

  setAutocomplete() {
    const controller = this
    if (!controller.hasSetAutocompleteTarget) { return }
    controller.autocomplete = new google.maps.places.Autocomplete(
      controller.setAutocompleteTarget,
      {
        componentRestrictions: {'country': ['us', 'rs']},
        fields: ["address_components", "geometry", "name"],
        types: ["address"],
      },
    );
    if (controller.hasFillInAddressTarget) {
      controller.autocomplete.addListener("place_changed", () => {
        controller.fillInAddress()
      });
    }
  }

  geocodeAddress() {
    let controller = this;
    let url = '/geocode_address';

    let address_elements = [
      controller.address1FieldTarget.value,
      controller.address2FieldTarget.value,
      controller.address3FieldTarget.value,
      controller.cityFieldTarget.value,
      controller.zipCodeFieldTarget.value,
      controller.stateFieldTarget.value,
      controller.countryFieldTarget.value,
    ];

    let address = {street: controller.address1FieldTarget.value,
      city: controller.cityFieldTarget.value,
      state: controller.stateFieldTarget.value,
      postcode: controller.zipCodeFieldTarget.value
    }

    let dData =  new FormData();
    dData.append("address[street]", address.street);
    dData.append("address[city]", address.city);
    dData.append("address[state]", address.state);
    dData.append("address[postcode]", address.postcode);

    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      data: dData,
      success: (response) => {
        let controller = this;

        if (response.error_message) {
          controller.geocoderErrorMessageTarget.innerHTML = response.error_message
          controller.geocoderErrorMessageTarget.style.visibility = "";
        } else {
          controller.latitudeTarget.value = response.latitude;
          controller.longitudeTarget.value = response.longitude;
          controller.timeZoneTarget.value = response.time_zone;

          let newLatLng = new google.maps.LatLng(response.latitude, response.longitude)
          controller.map.panTo(newLatLng);
          controller.map.setZoom(14);
          controller.marker.setPosition(newLatLng);

          controller.newLatitude = response.latitude;
          controller.newLongitude = response.longitude;

          controller.geocodedTarget.value = 1;
          controller.coordinatesVerifiedTarget.value = 'false';
          controller.verifyCoordinatesWrapperTarget.style.visibility = "";
          controller.coordinatesVerifiedFlagTarget.style.visibility = "hidden";
          controller.geocoderErrorMessageTarget.style.visibility = "hidden";
        }
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR)
      }
    })
  }

  verifyCoordinates() {
    this.latitudeTarget.value = this.newLatitude;
    this.longitudeTarget.value = this.newLongitude;
    this.coordinatesVerifiedTarget.value = 'true';
    this.coordinatesVerifiedFlagTarget.style.visibility = "";
  }

  addressChanged() {
    if (!this.hasGeocodedTarget) { return }

    this.geocodedTarget.value = 0;
  }

  setMap() {
    if(this.hasMapTarget) {
      let controller = this;
      let origLatLng = new google.maps.LatLng(this.origLatitude, this.origLongitude)
      this.map = new google.maps.Map(this.mapTarget, {
        center: origLatLng,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        zoom: this.origZoom
      })

      this.marker = new google.maps.Marker({
        position: origLatLng,
        map: this.map,
        draggable: true,
        animation: google.maps.Animation.DROP,
      });

      google.maps.event.addListener(this.marker, 'dragend', function(ev){
        let newPosition = controller.marker.getPosition();
        controller.newLatitude = newPosition.lat();
        controller.newLongitude = newPosition.lng();
      });

      google.maps.event.addListener(this.map, 'rightclick', function(e) {
        var positionDoubleclick = e.latLng;
        controller.marker.setPosition(positionDoubleclick);
        controller.newLatitude = positionDoubleclick.lat();
        controller.newLongitude = positionDoubleclick.lng();
      });
    }
  }

  fillInAddress() {
    const controller = this
    const place = controller.autocomplete.getPlace();
    let address1 = "";
    let postcode = "";
    if (place.address_components) {
      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number":
            address1 = component.long_name;
            break;
          case "route":
            address1 += ' ' + component.long_name;
            break;
          case "postal_code":
            postcode = component.long_name;
            break;
          case "postal_code_suffix":
            postcode = postcode + ' - ' + component.long_name;
            break;
          case "locality":
            controller.cityFieldTarget.value = component.long_name;
            break;
          case "administrative_area_level_1":
            controller.stateFieldTarget.value = component.short_name;
            if (controller.stateFieldTarget.slim) { controller.stateFieldTarget.slim.set(component.short_name) }
            break;
          case 'country':
            controller.countryFieldTarget.value = component.short_name;
            if (component.short_name == 'RS') {
              controller.stateFieldTarget.value = 'RS';
              if (controller.stateFieldTarget.slim) { controller.stateFieldTarget.slim.set('RS') }
            }
            break;
        }
      }
      controller.address1FieldTarget.value = address1;
      controller.zipCodeFieldTarget.value = postcode;
    }
  }
}
