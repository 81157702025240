import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['forceDuplicate', 'duplicates']

  connect() {
  }

  removeForceDuplicate() {
    if (this.hasForceDuplicateTarget) {
      this.forceDuplicateTarget.remove();
    }
  }
  onChangeSelectAll(event) {
    const checkboxes = this.duplicatesTarget.querySelectorAll('.check-box-field');
    if (event.currentTarget.checked === true) {
      checkboxes.forEach(item => {
        item.checked = true;
      })
    } else {
      checkboxes.forEach(item => {
        item.checked = false;
      })
    }
  }
}
