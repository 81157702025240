import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['columView', 'title', 'activity', 'activityContent', 'activityForm']

  connect() {}

  columViewTargetConnected(element) {
    this.setColumnHeight(element.closest('.dialog-form'))
  }

  activityTargetConnected() {
    this.setActivityContentHeight()
  }

  setActivityContentHeight() {
    let columnWrapperHeight = this.activityTarget.closest('.content-wrapper').offsetHeight
    let contentHeight = columnWrapperHeight - this.titleTarget.offsetHeight - this.activityFormTarget.offsetHeight
    this.activityContentTarget.style.height = `${contentHeight}px`
  }
  setColumnHeight(wrapper) {
    const columns = wrapper.querySelectorAll('.form-column');
    if (columns.length > 0 ) {
      let height = 500
      const title = wrapper.querySelector('.box-title');
      if (wrapper.closest('.overlay-content-wrapper')) {
        height = ( window.innerHeight - 30) - title.offsetHeight;
      } else {
        let headerHeight = document.querySelector('header').offsetHeight
        let menuHeight = document.getElementById('main_menu').offsetHeight
        height = window.innerHeight - title.offsetHeight - headerHeight - menuHeight;
      }

      let actionsBoxHeight = 0;
      columns.forEach(column => {
        column.style.height = `${height}px`;
        let columnContentWrapper = column.querySelector('.content-wrapper');
        let actionsBox = column.querySelector('.content-box.actions');
        if (actionsBox !== null) {
          actionsBoxHeight = actionsBox.offsetHeight
          columnContentWrapper.style.height = `${(height - actionsBoxHeight)}px`;
        }
      });
    }
  }
}
