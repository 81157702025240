import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'
import { isEmpty, isNil, isNull, isArray } from 'lodash/lodash';
import { DateTime } from 'luxon';
import flatpickr from 'flatpickr'
import Inputmask from 'inputmask'
import { dateOptions } from '../helpers/datetime_config';
import { showOverlay, hideOverlay } from '../helpers/overlays';

export default class extends Controller {
  static targets = [ 'date', 'termLength', 'dueDate', 'email', 'lastEmail', 'form', 'emailForm',
                     'afterUpdate', 'taskForm' ]
  static values = { pdfUrl: String, cancelEmailUrl: String }
  loader;
  dateFP;
  dueDateFP;

  connect() {
    this.loader = document.querySelector('.page-loader');
  }

  afterUpdateTargetConnected(target) {
    const controller = this
    if (target.dataset.formAction == 'send_mail') {
      controller.saveAndSend(target)
    } else if (target.dataset.formAction == 'total_changed') {
      let taskButton = document.getElementById('show_invoice_new_task_form')
      taskButton.click()
    } else {
      const box = target.closest('.overlay-content-wrapper')
      const boxId = box.id
      const frameId = box.dataset.frameId;
      const frame = document.querySelector(`turbo-frame#${frameId}`);
      frame.src = ''
      hideOverlay(boxId)
    }
  }

  emailFormTargetConnected(target) {
    const controller = this
    let markAsSentField = document.getElementById('invoice_mark_as_sent_true')
    var radioButtons = target.querySelectorAll('input[type="radio"]');
    let emailBox = target.querySelector('.email-box')
    radioButtons.forEach(radio => {
      radio.addEventListener('change', (event) => {
        if ((event.currentTarget.id == 'invoice_mark_as_sent_true') && (event.currentTarget.value == 'true')) {
          emailBox.style.display = 'none'
        } else {
          emailBox.style.display = 'block'
        }
      })
    })
    target.addEventListener('turbo:submit-end', (event)  => {
      controller.email(event)
    });
  }

  taskFormTargetConnected(target) {
    const controller = this
    target.addEventListener('turbo:submit-end', (event)  => {
      controller.task(event)
    });
  }


  dateTargetConnected() {
    this.dateFP = flatpickr(this.dateTarget, dateOptions())
    this.dateMask(this.dateFP.altInput)
  }

  dueDateTargetConnected() {
    this.dueDateFP = flatpickr(this.dueDateTarget, dateOptions())
    this.dateMask(this.dueDateFP.altInput)
    if (_.isEmpty(this.dueDateTarget.value)) {
      this.setDueDate()
    }
  }

  lastEmailTargetConnected() {
    this.updateEmailSelectBoxItem(this.lastEmailTarget)
  }

  setDueDate() {
    if (_.isEmpty(this.dateTarget.value)) { return }

    let invDate = DateTime.fromSQL(this.dateTarget.value, { zone: 'utc' })
    let dueDate = invDate.plus({ days: parseInt(this.termLengthTarget.value || 0) });
    let dueDateFormated = dueDate.toFormat('yyyy-MM-dd')
    let date = new Date(dueDateFormated)
    let duaDateFormatedAlt = dueDate.toFormat('MM-dd-yyyy')
    this.dueDateFP.setDate(date, true, duaDateFormatedAlt)
  }

  dateMask(element) {
    let mask = Inputmask({ mask: '99-99-9999' }).mask(element)
  }

  updateEmailSelectBoxItem(element) {
    const selectElements = this.emailTargets
    const text = element.dataset.email
    const value = element.dataset.email
    selectElements.forEach((item, i) => {
      const existedOption = item.querySelector(`option[data-id="${element.dataset.id}"]`);
      if (_.isNull(existedOption)) {
        let option = new Option(text, value, false, item.value == '' );
        option.dataset.id = element.dataset.id;
        item.add(option);
        item.tomselect.addOption({ value: value, text: text, id: element.dataset.id });
        item.tomselect.getValue()
        if (_.isEmpty(item.tomselect.getValue())) { item.tomselect.addItem(value) }
      } else {
        item.tomselect.updateOption(existedOption.value, { value: value, text: text, id: element.dataset.id })
      }
    });
  }

  saveAndSend(target) {
    const controller = this
    const box = target.closest('.overlay-content-wrapper')
    const boxId = box.id
    let sendButton = document.getElementById('show_invoice_email_form')
    controller.loader.style.display = 'block';
    fetch(controller.pdfUrlValue)
      .then((r) => r.text())
      .then((html) => {
      // controller.contentTarget.innerHTML = html;
      })
      .then((response) => {
        sendButton.click()
        controller.loader.style.display = 'none';
      })
      .catch(error => {
        console.error('Error:', error);
        controller.loader.style.display = 'none';
      });
  }

  email(event) {
    const controller = this
    if (event.detail.success) {
      const box = event.currentTarget.closest('.overlay-content-wrapper')
      const boxId = box.id
      const frameId = box.dataset.frameId;
      const frame = document.querySelector(`turbo-frame#${frameId}`);
      frame.src = ''
      hideOverlay(boxId)
      const formBox = controller.formTarget.closest('.overlay-content-wrapper')
      const formBxId = formBox.id
      hideOverlay(formBxId)
    } else {
      this.loader.style.display = 'none';
    }
  }

  task(event) {
    const controller = this
    if (event.detail.success) {
      const box = event.currentTarget.closest('.overlay-content-wrapper')
      const boxId = box.id
      const frameId = box.dataset.frameId;
      const frame = document.querySelector(`turbo-frame#${frameId}`);
      frame.src = ''
      hideOverlay(boxId)
      const formBox = controller.formTarget.closest('.overlay-content-wrapper')
      const formBxId = formBox.id
      hideOverlay(formBxId)
    } else {
      this.loader.style.display = 'none';
    }
  }

  cancelEmail(event) {
    event.preventDefault()
    const controller = this
    const box = event.currentTarget.closest('.overlay-content-wrapper')
    const boxId = box.id
    const options = {
      method: 'POST',
    };

    fetch(controller.cancelEmailUrlValue)
      .then(response => response.text())
      .then(data => {
        hideOverlay(boxId);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  setTruckLoadStatus(event) {
    let itemId = event.currentTarget.dataset.itemId
    let status = event.currentTarget.dataset.status;
    let url = `/billing/truck_loads/${itemId}`
    let dData =  new FormData();
    dData.set('status', status);
    const response = patch(url, {
      query: dData,
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
    } else {
      console.log('Error')
    }
  }
}
