import NestedForm from "stimulus-rails-nested-form"
import * as Rails from '@rails/ujs';

export default class extends NestedForm {
  static values = { puId: Number }

  connect() {
    super.connect();
    if (this.puIdValue) {
      this.setBusinessAssociateId()
    }
  }

  add(e) {
    super.add(e)
    this.targetTarget;
  }

  remove(e) {
    super.remove(e)
  }

  setBusinessAssociateId() {
    const controller = this
    let dData =  new FormData();
    dData.set('pu_id', controller.puIdValue);
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/trailers/power_unit_subleases',
      data: dData,
      success: (response) => {
        let selectBox = controller.templateTarget.content.querySelector("[data-association-fields-target='field']");
        if (response.business_associate_id != '') {
          selectBox.value = response.business_associate_id
          selectBox.options.forEach((option, i) => {
            if (option.value == response.business_associate_id) {
              option.setAttribute('selected', 'selected');
            }
          });
        }
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR)
      }
    })
  }
}
