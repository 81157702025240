import { Controller } from '@hotwired/stimulus'
import { getLast, getLastId } from '../helpers/dialog_registry';
export default class extends Controller {
  static targets = ['button']

  connect() {
    const controller = this
    let parameters = new URLSearchParams(window.location.search);
    let showDialog = parameters.get('show_dialog')
    if (controller.hasButtonTarget && showDialog == 'true') controller.showNewRecordDialog()
    let showInDialog = parameters.get('entity_in_dialog')
    if (showInDialog) controller.showEntityForm(parameters)
  }

  disconnect() {}

  showNewRecordDialog() {
    const controller = this
   
    controller.buttonTarget.click();
    let newUrl = window.location.href.replace(window.location.search, '');
    window.history.replaceState(null, null, newUrl);
  }

  showEntityForm(parameters) {
    const controller = this
    const dialogController = controller.application.getControllerForElementAndIdentifier(controller.element, 'dialog')
    let newUrl = window.location.href.replace(window.location.search, '');
    let entity = parameters.get('entity_in_dialog')
    let dilaog = document.getElementById(`overlay_${entity}_dialog`);
    let frame = dilaog.querySelector('turbo-frame');

    window.history.replaceState(null, null, newUrl);
    dialogController.showOverlay(entity)
    frame.src = `${newUrl}/${parameters.get('id')}/edit`
  }
}
