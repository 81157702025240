import { Controller } from '@hotwired/stimulus'
// import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['template']

  connect() {
    if (this.hasTemplateTarget) {
      this.setFields()
    }
  }

  setFields() {
    const controller = this;
    const form = document.getElementById('tl_form');
    if (form) {
      const formData = new FormData(form);
      let truckLoad = {};
      Array.from(formData.entries())
        .filter((line) => line[0].startsWith('truck_load'))
        .filter((line) => !/\[id\]/.test(line[0]))
        .filter((line) => !/\[driver_id\]/.test(line[0]))
        .filter((line) => !/\[power_unit_id\]/.test(line[0]))
        .filter((line) => !/\[trailer_id\]/.test(line[0]))
        .filter((line) => !/\[is_subunit\]/.test(line[0]))
        .filter((line) => !/\[status\]/.test(line[0]))
        .filter((line) => !/\[work_order\]/.test(line[0]))
        .filter((line) => !/\[warehouse_start_at\]/.test(line[0]))
        .filter((line) => !/\[warehouse_end_at\]/.test(line[0]))
        .filter((line) => !/\[warehouse_start_at_date\]/.test(line[0]))
        .filter((line) => !/\[warehouse_start_at_time\]/.test(line[0]))
        .filter((line) => !/\[warehouse_end_at_time\]/.test(line[0]))
        .filter((line) => !/\[files\]/.test(line[0]))
        .filter((line) => !/\[_destroy\]/.test(line[0]))
        .forEach((line) => {
          let result = /^truck_load\[(.*)\]$/gim.exec(line[0]);
          if (result.length > 1) {
            if (/\[.*\]/gi.test(result[1])) {
              const subKeys = result[1].split('][');
              if (!truckLoad[subKeys[0]]) truckLoad[subKeys[0]] = {};
              if (!truckLoad[subKeys[0]][subKeys[1]])
                truckLoad[subKeys[0]][subKeys[1]] = {};
              truckLoad[subKeys[0]][subKeys[1]][subKeys[2]] = line[1];
            } else if (/\]\[/gi.test(result[1])) {
              const subKeys = result[1].split('][');
              if (!truckLoad[subKeys[0]]) truckLoad[subKeys[0]] = {};
              truckLoad[subKeys[0]][subKeys[1]] = line[1];
            } else {
              truckLoad[result[1]] = line[1];
            }
          }
        });
        controller.templateTarget.value = JSON.stringify(truckLoad)
    }
  }
}
