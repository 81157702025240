export function setSelectedLoad() {
  let selectedItemId = localStorage.getItem('selectedItemId');
  let selectedItem = localStorage.getItem('selectedItem');
  let icon = document.createElement('i');
  icon.classList.add('las', 'la-check');

  if (selectedItemId != '') {
    let row = document.querySelector(`.dispatches-board table tr#${selectedItem}_${selectedItemId}`);
    if (row && !row.classList.contains('selected')) {
      row.classList.add('selected');
      let status = row.getElementsByClassName('status-circle');
      status[0].style.display = 'none';
      row.querySelector('.status').appendChild(icon);
    }
  }
}

export function isAvalableLoadSelected() {
  return (localStorage.getItem('selectedItem') === 'dispatch_tl') && (localStorage.getItem('loadType') === 'available')
}

export function isEnrouteLoadSelected() {
  return (localStorage.getItem('selectedItem') === 'dispatch_tl') && (localStorage.getItem('loadType') === 'enroute')
}

export function isPowerUnitLoadSelected() {
  return (localStorage.getItem('selectedItem') === 'power_unit')
}