import { Controller } from '@hotwired/stimulus';
import { fontColor } from '../helpers/colors';

export default class extends Controller {
  static targets = ['row', 'cell']

  connect() {
    let cells = this.element.querySelectorAll(".notes-background")
    cells.forEach(cell => {
      cell.style.color = fontColor(cell.style.backgroundColor)
    });
  }

  cellTargetConnected(element) {
    if (element.style.backgroundColor) {
      element.style.color = fontColor(element.style.backgroundColor)
    }
  }

  rowTargetConnected(element) {
    let cells = this.element.querySelectorAll(".notes-background")
    cells.forEach(cell => {
      cell.style.color = fontColor(cell.style.backgroundColor)
    });
  }

  editOnDblClick(event) {
    let tableRow = event.currentTarget
    let actionButton = tableRow.querySelector('.dblclick-edit')
    actionButton.click();
  }

  viewOnDblClick(event) {
    let tableRow = event.currentTarget
    let actionButton = tableRow.querySelector('.dblclick-view')
    actionButton.click();
  }
}
