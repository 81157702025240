import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['tableView']

  connect() {
    const controller = this
  }

  disconnect() {}

  toggleFieldsFor(event) {
    const controller = this
    let button = event.currentTarget
    let dataId = event.currentTarget.dataset['id'];
    let boxId = 'fields_for_' + dataId;
    let fieldsForBox = document.getElementById(boxId);
    let table = this.tableViewTarget;

    if (fieldsForBox) {
      fieldsForBox.classList.toggle('hidden');
      controller.highlightFieldsFor(button, boxId);
      if (fieldsForBox.classList.contains('hidden')) {
        controller.setShowButton(button)
      } else {
        controller.setHideButton(button)
      }
    }
    let allRow = table.querySelectorAll('tr').length
    let allOpenForm = this.element.querySelectorAll('.nested-form-wrapper:not(.hidden)').length
    let allHiddenForm = this.element.querySelectorAll('.nested-form-wrapper.hidden').length

    let showAllbutton = this.element.querySelector('a.show-hide-all')
    let showIcon = showAllbutton.querySelector('i.show');
    let hideIcon = showAllbutton.querySelector('i.hide');

    if ((allRow == allOpenForm) && (allRow > 0)) {
      showAllbutton.dataset.type = 'hide_all';
      showAllbutton.setAttribute('aria-label', 'Hide Fields');
      showIcon.style.display = 'none';
      hideIcon.style.display = 'inline-block';
    }
    if ((allRow == allHiddenForm) && (allRow > 0)) {
      showAllbutton.dataset.type = 'show_all';
      showAllbutton.setAttribute('aria-label', 'Show All Fields');
      hideIcon.style.display = 'none';
      showIcon.style.display = 'inline-block';
    }
  }

  hideFieldsFor(event) {
    const controller = this
    let button = event.currentTarget
    let dataId = event.currentTarget.dataset['id'];
    let table = this.tableViewTarget;
    let row = table.querySelector(`a[data-id='${dataId}']`)
    if (row) {
      row.dispatchEvent(new Event('click'));
    }
  }

  setShowButton(button) {
    let showIcon = button.querySelector('i.show');
    let hideIcon = button.querySelector('i.hide');
    button.setAttribute('aria-label', 'Show Fields');
    hideIcon.style.display = 'none';
    showIcon.style.display = 'inline-block';
  }

  setHideButton(button) {
    let showIcon = button.querySelector('i.show');
    let hideIcon = button.querySelector('i.hide');
    button.setAttribute('aria-label', 'Hide Fields');
    showIcon.style.display = 'none';
    hideIcon.style.display = 'inline-block';
  }

  setShowHideAll(button) {
    let showIcon = button.querySelector('i.show');
    let hideIcon = button.querySelector('i.hide');
    let type = button.dataset.type
    if (type == 'show_all') {
      button.dataset.type = 'hide_all';
      showIcon.style.display = 'none';
      hideIcon.style.display = 'inline-block';
      button.setAttribute('aria-label', 'Hide All Fields');
    } else {
      button.dataset.type = 'show_all';
      hideIcon.style.display = 'none';
      showIcon.style.display = 'inline-block';
      button.setAttribute('aria-label', 'Show All Fields');
    }
  }

  highlightFieldsFor(button, boxId) {
    let currentRow = button.closest('tr')
    let allHighlightedForm = this.element.querySelectorAll('.nested-form-wrapper.selected');
    let table = this.tableViewTarget;
    let allSelectedRow = table.querySelectorAll('.selected')
    allSelectedRow.forEach((row) => {
      row.classList.remove('selected');
    });

    allHighlightedForm.forEach(form => {
      form.classList.remove('selected');
    });

    let fieldsForBox = document.getElementById(boxId);
    if (fieldsForBox) {
      if (!fieldsForBox.classList.contains('hidden')) {
        fieldsForBox.classList.add('selected');
        currentRow.classList.add('selected');
      }
    }
  }

  toggleAllFieldsFor(event) {
    const controller = this
    let button = event.currentTarget
    let table = this.tableViewTarget;
    let allButtons = table.querySelectorAll('a.show-hide')
    let type = button.dataset.type
    allButtons.forEach((button) => {
      let dataId = button.dataset.id;
      let boxId = 'fields_for_' + dataId;
      let fieldsForBox = document.getElementById(boxId);
      if (fieldsForBox) {
        if (type == 'show_all') {
          fieldsForBox.classList.remove('hidden')
          controller.setHideButton(button);
        } else {
          fieldsForBox.classList.add('hidden')
          controller.setShowButton(button);
        }
      }
    });
    controller.setShowHideAll(button)
  }

  onSlectRow(event) {
    const controller = this
    let button = event.currentTarget;
    let dataId = event.currentTarget.dataset.id;
    let boxId = 'fields_for_' + dataId;
    let fieldsForBox = document.getElementById(boxId);
    if (fieldsForBox) {
      controller.highlightFieldsFor(button, boxId)
    }
  }
}
