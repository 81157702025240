import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr'
import Inputmask from 'inputmask'
import { dateOptions, timeOptions } from '../helpers/datetime_config';
export default class extends Controller {
  static targets = [ 'date', 'time', 'datetime', 'range' ]
  static values = { default: String, defaultRange: String, max: String, min: String }

  dateTargetConnected(target) {
    let options = dateOptions()
    if (this.hasMaxValue) { options.maxDate = this.maxValue }
    if (this.hasMinValue) { options.minDate = this.minValue }
    if (this.hasDefaultValue) { options.defaultDate = this.defaultValue }

    let fp = flatpickr(this.element, options)
    let mask = Inputmask({
      mask: '99-99-9999',
    }).mask(fp.altInput)
  }

  timeTargetConnected(target) {
    let options = timeOptions()
    if (this.hasDefaultValue) { options.defaultDate = this.defaultValue }

    let fp = flatpickr(this.element, options)
    let mask = Inputmask({
      mask: '99:99',
    }).mask(fp.altInput)
  }

  timeTargetDisconnected(target) {
    target.flatpickr().destroy()
  }

  rangeTargetConnected(target) {
    let options = { mode: 'range' }
    if (this.hasDefaultRangeValue) {
      let range = this.defaultRangeValue.split(' to ')
      options.defaultDate = range
    }
    if (this.hasMaxValue) {
      options.maxDate = this.maxValue
    }
    if (this.hasMinValue) {
      options.minDate = this.minValue
    }

    let fp = flatpickr(this.element, options)
  }
}
