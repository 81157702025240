import { Controller } from '@hotwired/stimulus';
import { DateTime } from 'luxon';
import { isNil, isNull, isEmpty } from 'lodash/lodash';

export default class extends Controller {
  static targets = ['dateTime', 'date', 'time', 'timeZone', 'range', 'timeSummary'];
  static values = { date: String, endDate: String, name: String, noRange: Boolean }

  dateInTZ;
  timeInTZ;
  endTimeInTZ;

  connect() {
    if (this.hasTimeTarget) this.dateAndTimeInTZ()
    if (this.hasRangeTarget) this.dateAndRangeInTZ()
    if (this.hasTimeSummaryTarget) this.tlWarehouseAtDateSummary()
  }

  dateAndTimeInTZ() {
    if (!_.isEmpty(this.dateValue) && !_.isEmpty(this.nameValue)) {
      let date = DateTime.fromSQL(this.dateValue, { zone: 'utc' })
      let dateInTZ = date.setZone(this.nameValue)

      this.timeInTZ = dateInTZ.toLocaleString(DateTime.TIME_24_SIMPLE)
      this.dateInTZ = dateInTZ.toFormat('MM-dd-yyyy')
      this.dateTarget.textContent = this.dateInTZ
      this.timeTarget.textContent = this.timeInTZ
    }
  }

  dateAndRangeInTZ() {
    if (!_.isEmpty(this.dateValue) && !_.isEmpty(this.nameValue)) {
      let date = DateTime.fromSQL(this.dateValue, { zone: 'utc' })
      let dateInTZ = date.setZone(this.nameValue)
      this.timeInTZ = dateInTZ.toLocaleString(DateTime.TIME_24_SIMPLE)
      this.dateInTZ = dateInTZ.toFormat('MM-dd-yyyy')
      this.dateTarget.textContent = this.dateInTZ

      let endDate = DateTime.fromSQL(this.endDateValue, { zone: 'utc' })
      let endDateInTZ = endDate.setZone(this.nameValue)
      this.endTimeInTZ = endDateInTZ.toLocaleString(DateTime.TIME_24_SIMPLE)
      this.rangeTarget.textContent = `${this.timeInTZ} - ${this.endTimeInTZ}`
      if ((this.hasNoRangeValue && this.noRangeValue) || (this.dateValue === this.endDateValue) || _.isEmpty(this.endDateValue)) {
        this.rangeTarget.textContent = this.timeInTZ
      }
    }
  }

  tlWarehouseAtDateSummary() {
    if (!_.isEmpty(this.dateValue) && !_.isEmpty(this.nameValue)) {
      let date = DateTime.fromSQL(this.dateValue, { zone: 'utc' })
      let endDate = DateTime.fromSQL(this.endDateValue, { zone: 'utc' })
      let dateInTZ = date.setZone(this.nameValue)
      let endDateInTZ = endDate.setZone(this.nameValue)

      this.timeInTZ = dateInTZ.toLocaleString(DateTime.TIME_24_SIMPLE)
      this.endTimeInTZ = endDateInTZ.toLocaleString(DateTime.TIME_24_SIMPLE)
      this.dateInTZ = dateInTZ.toFormat('MM/dd')
      this.dateTarget.textContent = this.dateInTZ
      this.timeSummaryTarget.textContent = `${this.timeInTZ} - ${this.endTimeInTZ}`
      if ((this.hasNoRangeValue && this.noRangeValue) || (this.dateValue === this.endDateValue)) {
        this.timeSummaryTarget.textContent = `@ ${this.timeInTZ}`
      }
    }
  }
}
