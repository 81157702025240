
export function showOverlay(boxId) {
  const box = document.getElementById(`overlay_${boxId}_dialog`);
  box.classList.add('overlay-show');
  box.classList.add('zoom-in');
  box.classList.remove('overlay-hide');
  box.classList.remove('zoom-out');
}

export function hideOverlay(boxId) {
  const allAlerts = document.getElementsByClassName('alert-error');
  allAlerts.forEach(link => { link.classList.remove('alert-error') })
  const box = document.getElementById(boxId);
  box.classList.remove('zoom-in');
  box.classList.add('zoom-out');
  setTimeout((() => {
    box.classList.remove('overlay-show');
    box.classList.add('overlay-hide');
  }), 200);
}