import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'
import { isEmpty, uniq }from 'lodash/lodash';
import { getControllerByIdentifier } from 'helpers';
import { showOverlay, hideOverlay } from '../helpers/overlays';


export default class extends Controller {
  static targets = ['checkbox', 'bar', 'toggleElement', 'counter', 'close']
  static values = { url: String }
  effectsController;

  connect() {
  }

  checkboxTargetConnected() {
    const controller = this
    const data = this.checkboxTargets.filter(element => element.dataset.doNotShow).map(element => element.dataset.doNotShow)
    const actionNames = _.uniq(data)
    let elements = []
    if (_.isEmpty(actionNames)) {
      let elements = controller.barTarget.querySelectorAll('.actions-button')
      elements.forEach(element => {
        element.style.display = 'block'
      });
    } else {
      actionNames.forEach(name => {
        controller.barTarget.querySelector(`[data-name='${name}']`).style.display = 'none'
      });
    }

  }

  toggleGroup(event) {
    if (event.currentTarget.checked === true) {
      this.checkboxTargets.forEach(item => {
        item.checked = true;
      })
      this.showBar()
    } else {
      this.checkboxTargets.forEach(item => {
        item.checked = false;
      })
      this.hideBar()
    }
  }

  deslectAll() {
    this.toggleElementTarget.checked = false
    this.checkboxTargets.forEach(item => {
      item.checked = false;
    })
  }

  async process(event) {
    const controller = this
    let target  = event.currentTarget
    let effectsController = getControllerByIdentifier(this, 'effects');

    if (!target.dataset) { return }
    let ids = [];

    this.checkboxTargets.forEach(item => {
      if (item.checked) { ids.push(item.value); }
    })
    if (_.isEmpty(ids)) {
      effectsController.flash( { detail: {
        message: 'You need to select records' , wrapperClass: 'alert-error', icons: ['las', 'la-exclamation-triangle']
      }})
      return
    }

    if (target.dataset.format == 'csv') {
      let idsString = ids.join(',')
      var url = new URL(target.dataset.urlValue);
      url.searchParams.set('ids', idsString);
      window.location.href = url;
    }
    if (target.dataset.format == 'new_record') {
      let idsString = ids.join(',')
      let dData =  new FormData();
      dData.set('ids', idsString);
      const response = await get(target.dataset.urlValue, {
        query: dData,
        responseKind: 'turbo-stream'
      })
      if (response.ok && response.response.status != 210) {
        showOverlay(target.dataset.box)
        controller.closeTarget.click()
      }
    }
  }

  setSelected() {
    this.counterTarget.innerHTML = this.selectedCount()
  }

  selectedCount() {
    let checkedCount = 0
    this.checkboxTargets.forEach(item => {
      if (item.checked) { checkedCount++ }
    })
    return checkedCount
  }

  singleSelect() {
    let checkedCount = this.selectedCount()

    if (checkedCount > 0) {
      this.showBar()
    } else  {
      this.hideBar()
      this.toggleElementTarget.checked = false
    }
  }

  showBar() {
    this.barTarget.classList.add('bar-show');
    this.barTarget.classList.add('zoom-in');
    this.barTarget.classList.remove('bar-hide');
    this.barTarget.classList.remove('zoom-out');
    this.setSelected()
  }

  hideBar() {
    this.barTarget.classList.remove('zoom-in');
    this.barTarget.classList.add('zoom-out');
    setTimeout((() => {
      this.barTarget.classList.remove('bar-show');
      this.barTarget.classList.add('bar-hide');
    }), 200);
    this.deslectAll()
  }
}
