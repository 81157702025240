import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['closeButton', 'quickSearch', 'tooltip', 'tooltipContent', 'toggleDropDown', 'reloadPage']
  sidebarWidth = '360px'

  closeButtonTargetConnected() {
    this.closeButtonTargets.forEach((button, i) => {
      let data = button.dataset.dismiss
      let fadeTarget = button.closest('.' + data)
      button.addEventListener('click', (event) => {
        let fadeEffect = setInterval(function () {
          if (!fadeTarget.style.opacity) { fadeTarget.style.opacity = 1; }
          if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.1;
          } else {
            clearInterval(fadeEffect);
            fadeTarget.remove()
          }
        }, 30);
      })

      setTimeout(()=> {
        fadeTarget.remove()
      }, 6000)
    });
  }

  closeButtonTargetDisconnected() {}

  tooltipTargetConnected(target) {
    var tooltip = tippy(target, {
      content: target.querySelector('.tooltip'),
      allowHTML: true,
      zIndex: 999999,
    });
  }

  toggleDropdown(event) {
    const parent = event.currentTarget.closest('.dropdown');
    parent.classList.toggle('active');
  }

  hideDropdown(event) {
    const parent = event.currentTarget.closest('.dropdown');
    parent.classList.remove('active');
  }

  toggleDropDownTargetConnected(target) {
    const parent = target.closest('.dropdown');
    document.addEventListener('click', (event) => {
      if (event.target.closest('.dropdown') == parent) return
      parent.classList.remove('active')
    })
  }

  openSidebar() {
    document.getElementById('sidebar').style.width = this.sidebarWidth;
    document.getElementById('main_content').style.marginLeft = this.sidebarWidth;
    document.querySelector('.sidebar-footer').classList.remove('hidden');
    document.querySelector('.sidebar-footer').style.width = this.sidebarWidth;
  }

  closeSidebar() {
    document.getElementById('sidebar').style.width = '0';
    document.getElementById('main_content').style.marginLeft = '0';
    document.querySelector('.sidebar-footer').classList.add('hidden');
    document.querySelector('.sidebar-footer').style.width = '0';
  }

  scrollTo(event) {
    var fullUrl, parts, offset, trgt;
    event.preventDefault();
    const element = event.currentTarget;

    fullUrl = element.getAttribute('href');
    parts = fullUrl.split("#");
    trgt = parts[1];
    offset = document.querySelector("#" + trgt).offsetTop;
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  }
}
