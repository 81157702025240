import { Controller } from '@hotwired/stimulus'
import * as Rails from '@rails/ujs';
import { get } from '@rails/request.js'
import { getControllerByIdentifier } from 'helpers'
import { DateTime } from 'luxon';
import { isNil, isNull, isEmpty } from 'lodash/lodash';

export default class extends Controller {
  static targets = ['form', 'status', 'createdCustomer', 'createdFreightClass', 'createdCommodity',
    'shipperId', 'receiverId', 'freightClassId', 'commodityId', 'rateType',
    'milesField']

  documentsColumn;

  connect() {
    this.documentsColumn = document.getElementById('documents_wrapper');
    const controller = this

    window.addEventListener('unload', () => {
      controller.clearPDFData()
    })
  }

  statusTargetConnected(element) {
    this.setMilesFieldsEditable(element.value)
    element.addEventListener('change', (event) => {
      this.tlUnitsDeselectOption(event)
      this.setMilesFieldsEditable(event.currentTarget.value)
    })
  }

  createdCustomerTargetConnected(element) {
    const selectElement = document.getElementById('truck_load_customer_id');
    const text = element.dataset.name
    const value = element.dataset.id
    selectElement.add(new Option(text, value, false, selectElement.value == ''));
    this.updateCustomersQueue();
  }

  createdFreightClassTargetConnected(element) {
    const selectElement = document.getElementById('truck_load_freight_class_id');
    const text = element.dataset.name
    const value = element.dataset.id
    selectElement.add(new Option(text, value, false, true));
    let groupIndex = this.commodityIdTarget.querySelectorAll('optgroup').length
    let optgroup = document.createElement('optgroup');
    optgroup.label = text
    optgroup.dataset.fcId = value
    optgroup.dataset.idx = groupIndex + 1
    this.commodityIdTarget.appendChild(optgroup)
  }

  createdCommodityTargetConnected(element) {
    const fcId = element.dataset.fcId
    const group = this.commodityIdTarget.querySelector(`optgroup[data-fc-id="${fcId}"]`)
    const text = element.dataset.name
    const value = element.dataset.id
    this.commodityIdTarget.tomselect.addOption({value: value, optgroup: group.dataset.idx, text: text, fcId: fcId}, true);
    this.commodityIdTarget.tomselect.addItem(value)
    let newOption = this.commodityIdTarget.querySelector(`option[value="${value}"]`)
    newOption.dataset.fcId = fcId
    group.appendChild(newOption)
    this.commodityIdTarget.dispatchEvent(new Event('change'))
  }

  commodityIdTargetConnected(element) {
    element.addEventListener('change', (event) => {
      this.setFreightClassId(event)
    })
  }

  rateTypeTargetConnected(element) {
    element.addEventListener('change', (event) => {
      this.setFreightClassFieldVisibility()
    })
  }

  freightClassIdTargetConnected() {
    this.setFreightClassFieldVisibility()
  }

  setFreightClassFieldVisibility() {
    let field = this.freightClassIdTarget.closest('.field')
    if (this.rateTypeTarget.value === 'ltl') {
      field.classList.remove('hidden')
    } else {
      field.classList.add('hidden')
    }
  }

  formTargetConnected(element) {
    this.documentsColumn = document.getElementById('documents_wrapper');
    let fileName = localStorage.getItem('fileName');
    if (fileName) {
      let anchor = element.querySelector(`[data-file-name='${fileName}']`)
      if (anchor) {
        const wrapper =  document.getElementById('preview_wrapper');
        wrapper.innerHTML = ''
        if (anchor.dataset.previewTypeValue == 'preview') {
          this.showDocumentsView();
          const iframe = document.createElement('iframe');
          iframe.setAttribute('src', anchor.dataset.previewUrlValue)
          wrapper.appendChild(iframe);
        }
      }
    }

    let customers = this.customersQueueData() || []
    this.parsedPDFQueue(customers, 'customers', 'findCustomer')
    let shippers = this.shippersQueueData() || []
    let receivers = this.receiversQueueData() || []
    this.parsedPDFQueue(shippers, 'shippers', 'findWarehouse');
    this.parsedPDFQueue(receivers, 'receivers', 'findWarehouse');
  }

  customerIdTargetDisconnected(element) {}

  shipperIdTargetConnected(element) {
    const selectElements = document.querySelectorAll("[data-warehouse='shipper']")
    let button =  document.querySelector('.attach-shipper');
    if (selectElements.length > 0) {
      this.setShipperToSelectBox(element);
    } else {
      button.click();
      this.setShipperToSelectBox(element);
    }
  }

  shipperIdTargetDisconnected(element) {}

  setShipperToSelectBox(element) {
    this.addWarehouseSelectBoxItem(element, 'shipper', 'shippers');
  }

  receiverIdTargetConnected(element) {
    const selectElements = document.querySelectorAll("[data-warehouse='receiver']");
    let button =  document.querySelector('.attach-receiver');
    if (selectElements.length > 0) {
      this.setReceiverToSelectBox(element);
    } else {
      button.click();
      this.setReceiverToSelectBox(element);
    }
  }

  receiverIdTargetDisconnected(element) {}

  setReceiverToSelectBox(element) {
    this.addWarehouseSelectBoxItem(element, 'receiver', 'receivers')
  }

  addWarehouseSelectBoxItem(element, dataName, formName) {
    const controller = this;

    const template = document.querySelector(`template[data-truck-loads--${formName}-form-target]`);
    const selectElements = document.querySelectorAll(`[data-warehouse="${dataName}"]`);
    const selectElementsInTemplate = template.content.querySelectorAll(`[data-warehouse="${dataName}"]`);
    const text = element.dataset.name
    const value = element.dataset.id
    const address = element.dataset.address
    const timeZone = element.dataset.tZone
    selectElements.forEach((item, i) => {
      let option = new Option(text, value, false, item.value == '' );
      option.dataset.address = address;
      option.dataset.tZone = timeZone;
      item.add(option);
      controller.setWarehouseLocation(item);
    });
    selectElementsInTemplate.forEach((item, i) => {
      let option = new Option(text, value, false, false);
      option.dataset.address = address;
      option.dataset.tZone = timeZone;
      item.add(option);
    });

    let warehouse = localStorage.getItem('selectedWarehouse')
    if (warehouse) {
      let data = JSON.parse(warehouse);
      if (data.type == formName) {
        data.id = element.dataset.id
        setTimeout(() => controller.setWarehouse(dataName, data), 50);
      }
    }
  }

  onChangeWarehouse(event) {
    const controller = this
    controller.setWarehouseLocation(event.currentTarget);
  }

  tlUnitsDeselectOption(event) {
    let tlUnits = getControllerByIdentifier(this, 'tl-units')
    if (event.currentTarget.value == 'open') {
      tlUnits.element.classList.remove('disable-deselect-options')
    } else {
      tlUnits.element.classList.add('disable-deselect-options')
    }
  }

  setWarehouseLocation(element) {
    let idx = element.selectedIndex;
    if (idx == -1) { return }
    let dataset = element.options[idx].dataset
    let address = dataset.address || '';
    let form = element.closest('.nested-form-wrapper')
    let addressField = form.querySelector('[id$="_warehouse_address"]')
    let locationField = form.querySelector('[id$="_location"]')
    let timeZone = form.querySelector('[id$="_warehouse_time_zone"]')
    addressField.value = address
    let toBeConfirmed = dataset.special == 'to_be_confirmed'
    if (toBeConfirmed) {
      addressField.closest('.field').classList.add('hidden');
      locationField.closest('.field').classList.remove('hidden')
      timeZone.removeAttribute('readonly')
      timeZone.value = ''
    } else {
      locationField.closest('.field').classList.add('hidden');
      addressField.closest('.field').classList.remove('hidden')
      timeZone.value = dataset.tZone
      timeZone.setAttribute('readonly', 'readonly')
    }
    timeZone.dispatchEvent(new Event('change'));
  }

  toggleDocumentsView(event) {
    this.documentsColumn.classList.toggle('visible');
    if (this.documentsColumn.classList.contains('visible')) {
      this.element.classList.add('with-document-preview');
    } else {
      this.element.classList.remove('with-document-preview');
    }
  }

  showDocumentsView(event) {
    this.documentsColumn.classList.add('visible');
    this.element.classList.add('with-document-preview');
  }

  fromPDF(dataset) {
    let rateType = document.getElementById("truck_load_rate_mile_attributes_rate_type")
    let rate = document.getElementById("truck_load_rate_mile_attributes_rate")

    rateType.value = 'linehaul'
    rateType.dispatchEvent(new Event('change'));

    document.getElementById("truck_load_work_order").value = dataset.work_order
    rate.value = dataset.rate
    rate.dispatchEvent(new Event('change'));

    let linehaulValue = (_.isNil(dataset.base_rate)) ? dataset.rate : dataset.base_rate
    document.getElementById("truck_load_rate_mile_attributes_linehaul").value = linehaulValue

    if (!_.isNil(dataset.fsc)) { document.getElementById("truck_load_rate_mile_attributes_fsc").value = dataset.fsc }

    let customers = this.customersQueueData() || []
    this.parsedPDFQueue(customers, 'customers', 'findCustomer')

    let shippers = this.shippersQueueData() || []
    let receivers = this.receiversQueueData() || []

    this.parsedPDFQueue(shippers, 'shippers', 'findWarehouse');
    this.parsedPDFQueue(receivers, 'receivers', 'findWarehouse');
    if (shippers.length > 1) {
      let button =  document.querySelector('.attach-shipper');
      for (let i = 0; i < shippers.length - 1; i++) {
        button.click()
      }
    }

    if (receivers.length > 1) {
      let button = document.querySelector('.attach-receiver');
      for (let i = 0; i < receivers.length - 1; i++) {
        button.click()
      }
    }
  }

  parsedPDFQueue(entities, type, action) {
    let queueHTML = document.getElementById(`queued_${type}`)
    let body = queueHTML.querySelector('.body')
    let valueHTML = queueHTML.querySelector('.value')
    let count = 0

    if (entities.length > 0) {
      queueHTML.classList.remove('hidden');
    } else {
      queueHTML.classList.add('hidden');
      valueHTML.innerHTML = '';
    }

    body.innerHTML = '';
    entities.forEach((entity, i) => {
      count += 1
      let item = document.createElement('a');
      Object.keys(entity).forEach((key) => {
        item.dataset[key] = entity[key]
      })
      item.dataset.type = type
      item.href = 'javascript:void(0)';
      item.classList.add('item');

      item.dataset.action = `click->truck-load#${action}`;
      item.textContent += entity.name;
      body.appendChild(item);
      valueHTML.innerHTML = '(' + count + ')';
    });
  }

  findCustomer(element) {
    const controller = this
    let customer = element.currentTarget.dataset
    element.currentTarget.classList.add('selected')
    let dropdown = element.currentTarget.closest('.dropdown');
    let button = dropdown.querySelector('.notification-button');

    let dData =  new FormData();
    Object.keys(customer).forEach((key) => {
      dData.set(key, customer[key]);
    })
    dData.set('from_pdf', 'true');
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/customers/find',
      data: dData,
      success: (response) => {
        if (response.results.length == 0) {
          button.click()
          controller.showOwerlayFromTL('overlay_customer_dialog');
          get('/customers/new', {
            query: dData,
            responseKind: 'turbo-stream'
          })
        } else {
          if (response.results.length == 1) {
            const selectElement = document.getElementById('truck_load_customer_id');
            let custId = response.results[0].id
            selectElement.value = custId
            if (selectElement.slim) { selectElement.slim.set(custId) }
            controller.updateCustomersQueue();
          }
        }
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR)
      }
    })
  }

  updateCustomersQueue() {
    const queuedCustomers = document.getElementById('queued_customers');
    let updatedCustomers = null
    if (queuedCustomers) {
      let selected = queuedCustomers.querySelector('.item.selected');
      let customers = this.customersQueueData();
      updatedCustomers = customers.filter(function( obj ) {
        return obj.name !== selected.dataset.name;
      });
      this.setCustomersQueueData(updatedCustomers)
      this.parsedPDFQueue(updatedCustomers, 'customers', 'findCustomer')
    }
  }

  updateWarehousesQueue(type) {
    const queued = document.getElementById('queued_' + type);
    let updatedWarehouses = null
    if (queued) {
      let selected = queued.querySelector('.item.selected');
      if (type == 'shippers') {
        let warehouses = this.shippersQueueData();
        updatedWarehouses = warehouses.filter(function( obj ) {
          return obj.name !== selected.dataset.name;
        });
        this.setShippersQueueData(updatedWarehouses)
      } else {
        let warehouses = this.receiversQueueData();
        updatedWarehouses = warehouses.filter(function( obj ) {
          return obj.name !== selected.dataset.name;
        });
        this.setReceiversQueueData(updatedWarehouses)
      }
      this.parsedPDFQueue(updatedWarehouses, type, 'findWarehouse')
    }
  }

  findWarehouse(element) {
    const controller = this
    let warehouse = element.currentTarget.dataset
    let type = element.currentTarget.dataset.type
    let wType = type == 'shippers' ? 'shipper' : 'receiver'
    element.currentTarget.classList.add('selected')
    let dropdown = element.currentTarget.closest('.dropdown');
    let button = dropdown.querySelector('.notification-button');

    let dData =  new FormData();
    Object.keys(warehouse).forEach((key) => {
      dData.set(key, warehouse[key]);
    })
    localStorage.setItem('selectedWarehouse', JSON.stringify(warehouse));
    dData.set('from_pdf', 'true');
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/' + type + '/find',
      data: dData,
      success: (response) => {
        if (response.results.length == 0) {
          button.click()
          controller.showOwerlayFromTL('overlay_' + wType + '_dialog');
          get(`/${type}/new`, {
            query: dData,
            responseKind: 'turbo-stream'
          })
        } else {
          if (response.results.length == 1) {
            warehouse.id = response.results[0].id
            controller.setWarehouse(wType, warehouse);
          }
        }
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR)
      }
    })
  }

  setWarehouse(type, warehouse) {
    const list = document.querySelectorAll('.nested-form-wrapper.' + type)
    const form = list[warehouse.idx]

    let warehouseField = form.querySelector("select[id$=warehouse_id]");
    warehouseField.value = warehouse.id
    if (warehouseField.slim) { warehouseField.slim.set(warehouse.id) }
    localStorage.removeItem('selectedWarehouse');

    let timeZone = form.querySelector("select[id$=warehouse_time_zone]").value;
    let startAtString = warehouse.start_at.slice(0,16)
    let startDateTime = DateTime.fromSQL(startAtString, { zone: timeZone })
    let startDateTimeString = startDateTime.toUTC().toSQL();
    let warehouseStartAt = form.querySelector("input[id$=warehouse_start_at]");
    form.dataset.tlWarehouseStartDateValue = `${startDateTimeString}`
    warehouseStartAt.value = `${startDateTimeString}`
    if (warehouse.is_appointment === 'true') {
      form.querySelector("input[id$=is_appointment]").checked = true;
      form.querySelector("input[id$=is_appointment]").dispatchEvent(new Event('change'));
    }

    if (!_.isEmpty(warehouse.end_at) && warehouse.end_at !== 'null' && warehouse.is_appointment === 'false') {
      let endAtString = warehouse.end_at.slice(0,16)
      let endDateTime = DateTime.fromSQL(endAtString, { zone: timeZone })
      let endDateTimeString = endDateTime.toUTC().toSQL();
      let warehouseEndAt = form.querySelector("input[id$=warehouse_end_at]");
      form.dataset.tlWarehouseEndDateValue = `${endDateTimeString}`
      warehouseEndAt.value = `${endDateTimeString}`
    }

    let wType = type == 'shipper' ? 'shippers' : 'receivers'
    this.updateWarehousesQueue(wType);
  }

  showOwerlayFromTL(boxId) {
    const box = document.getElementById(boxId);
    const alertBox = document.querySelector('.alert-error');
    if (alertBox != null) { alertBox.remove(); }
    box.classList.add('overlay-show');
    box.classList.add('with-preview');
    box.classList.add('zoom-in');
    box.classList.remove('overlay-hide');
    box.classList.remove('zoom-out');
  }

  customersQueueData() {
    let dataString = localStorage.getItem('customersQueue');
    let customers = JSON.parse(dataString);
    if (customers) {
      return customers
    } else {
      return []
    }
  }

  shippersQueueData() {
    let shippersString = localStorage.getItem('shippersQueue');
    let shippers = JSON.parse(shippersString);
    return shippers
  }

  receiversQueueData() {
    let receiversString = localStorage.getItem('receiversQueue');
    let receivers = JSON.parse(receiversString);
    return receivers
  }

  setCustomersQueueData(data) {
    localStorage.setItem('customersQueue', JSON.stringify(data));
  }

  setShippersQueueData(data) {
    localStorage.setItem('shippersQueue', JSON.stringify(data));
  }

  setReceiversQueueData(data) {
    localStorage.setItem('receiversQueue', JSON.stringify(data));
  }

  clearPDFData() {
    localStorage.removeItem('fileName');
    localStorage.removeItem('selectedWarehouse');
    localStorage.removeItem('customersQueue');
    localStorage.removeItem('shippersQueue');
    localStorage.removeItem('receiversQueue');
  }

  setFreightClassId(event) {
    let idx = event.currentTarget.selectedIndex
    let option = event.currentTarget.options[idx]
    let fcId = option.dataset.fcId
    if (idx == 0 && option.value == '') { return }
    this.freightClassIdTarget.value = fcId
    this.freightClassIdTarget.slim.set(fcId);
  }

  setMilesFieldsEditable(status) {
    let editableStatuses = ['delivered', 'ready_for_invoice', 'completed']
    let isReadonly = editableStatuses.includes(status) ? false : true
    if (!this.hasMilesFieldTarget) { return }
    this.milesFieldTargets.forEach(target => {
      target.readOnly = isReadonly
    });
  }
}
