import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['wrapper']
  static values = { url: String, type: String };

  connect() {
  }

  disconnect() {}

  show(event) {
    event.preventDefault();
    const currentImage = event.currentTarget;
    const allImages = document.querySelectorAll('.dz-image a');
    const wrapper =  document.getElementById('preview_wrapper');
    wrapper.innerHTML = ''

    allImages.forEach(image => {
      image.classList.remove('selected');
    })
    currentImage.classList.add('selected')
    if (this.typeValue == 'preview') {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', this.urlValue)
      wrapper.appendChild(iframe);
    } else {
      const image = document.createElement('img');
      image.setAttribute('src', this.urlValue)
      wrapper.appendChild(image);
    }
  };
}
