import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';

export default class extends Controller {

  static targets = ['equipmentField', 'equipmentType', 'priceField',
    'associationForm', 'trailerForm', 'powerUnitForm', 'newAssociationForm']

  static values = {
    type: String,
    types: Object,
    url: String,
    price: String,
    prices: Object
  }

  connect() {
    const controller = this

    if (this.hasEquipmentTypeTarget) {
      this.equipmentTypeTarget.value = this.typeValue
    }
    if (this.hasPriceFieldTarget) {
      this.priceFieldTarget.value = controller.priceValue
    }

    if (this.hasUrlValue) {
      const url = new URL(controller.urlValue)
      url.searchParams.append('type', controller.typeValue)
      fetch(url)
        .then(response => response.json())
        .then(result => {
          controller.setOptionsToEquipment(result);
        })
        .catch(() => {})
    }
  }

  newAssociationFormTargetConnected(target) {
    let priceField = target.querySelector('[data-field="price"]')
    let equipment_type = document.getElementById('equipment_equipment_type').value

    if (priceField) {
      priceField.value = this.pricesValue[equipment_type]
    }
  }

  setOptionsToEquipment(results) {
    const controller = this
    controller.equipmentFieldTarget.slim.destroy()
    controller.equipmentFieldTarget.innerHTML = ''
    let placeholder = document.createElement('option')
    let options = null
    let data = [];
    placeholder.value = ''
    placeholder.text = 'Select Equipment...'
    controller.equipmentFieldTarget.add(placeholder);
    results.forEach((item, i) => {
      let option = new Option(item.serial_number, item.id, false, false );
      option.dataset.notSelectable = item.status != 'available' && item.status != 'inactive'
      controller.equipmentFieldTarget.add(option);
    });
    options = controller.equipmentFieldTarget.options
    options.forEach((item) => {
      let disabled = item.dataset.notSelectable == 'true'
      let innerHTML = '';
      if (item.value == "") {
        data.push({ value: '', text: '', data: { placeholder: true } })
      } else {
        data.push({ value: item.value, text: item.text, disabled: disabled, selected: item.selected, data: item.dataset })
      }
    });
    let sSelect = new SlimSelect({ placeholder: 'Select Equipment...',
      select: controller.equipmentFieldTarget, selected: true, data: data,
      allowDeselect: controller.equipmentFieldTarget.dataset.allowDeselect === 'true' })
  }

  onChangeType(event) {
    let type = event.currentTarget.value;
    this.trailerFormTargets.forEach((item) => {
      if (type == 'trailer_tracking') {
        item.classList.remove('hidden-box');
      } else {
        item.classList.add('hidden-box');
      }
    });
    this.powerUnitFormTargets.forEach((item) => {
      if (type == 'trailer_tracking') {
        item.classList.add('hidden-box');
      } else {
        item.classList.remove('hidden-box');
      }
    });
  }
}
