import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // static targets = ['source']

  change(event) {
    let tabId = event.currentTarget.dataset.tab
    let alltabsLink = document.querySelectorAll('ul.tabs li');
    let alltabsContent = document.querySelectorAll('.tab-content');

    alltabsLink.forEach(link => {
      link.classList.remove('current');
    })

    alltabsContent.forEach(tab => {
      tab.classList.remove('current');
    })

    document.querySelector('.tab-link[data-tab=' + tabId + ']').classList.add('current');
    document.getElementById(tabId).classList.add('current')
  }
}
