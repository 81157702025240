import { Controller } from '@hotwired/stimulus'
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['form',  'pageWrapper', 'formFields', 'dropZone', 'documentsList', 'documentsForm']
  contentHeight;

  connect() {
  }

  pageWrapperTargetConnected() {
    const controller = this
    controller.setContentHeight()
  }

  documentsListTargetConnected() {
    let listHeight = 300
    listHeight = this.contentHeight - (this.formFieldsTarget.offsetHeight + this.dropZoneTarget.offsetHeight)

    this.documentsListTarget.style.height = `${listHeight}px`
  }

  submit(event) {
    if (event.detail.success) {
      const form = document.getElementById('directory_form')
      form.innerHTML = ''
      // let errorsClass = this.formTarget.querySelectorAll('.field_with_errors')
      // errorsClass.forEach(error => { error.classList.remove('field_with_errors')})
    }
  }

  uploadDocuments(event) {
    if (event.detail.success) {
      let elements = this.dropZoneTarget.querySelectorAll('.dz-preview')
      elements.forEach(element => {
        element.remove()
      });
      let message = this.dropZoneTarget.querySelector('.dz-message')
      message.style.display = 'block'
    }

  }

  onEnter(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  submitForm(event) {
    event.preventDefault()
    Rails.fire(this.formTarget, 'submit');
  }

  submitDocumentsForm(event) {
    event.preventDefault()
    Rails.fire(this.documentsFormTarget, 'submit');
  }

  setContentHeight() {
    let navHeight = document.querySelector('nav.main-nav').offsetHeight
    let headerHeight = document.querySelector('.content-header').offsetHeight
    this.contentHeight = window.innerHeight - navHeight - headerHeight - 23
    this.pageWrapperTarget.style.height = `${this.contentHeight}px`
  }
}