export function fontColor(bgColor) {
  var o, rgb;

  if (bgColor.match(/^rgb/)) {
    let color = bgColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    rgb = { r: parseInt(color[1]),
            g: parseInt(color[2]),
            b: parseInt(color[3]),
    }
  } else {
    rgb = hexToRgb(bgColor)
  }

  if (rgb != null) {
    o = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);
    if (o > 128) {
      return '#262f36';
    } else {
      return '#ffffff';
    }
  }
};

export function hexToRgb(hex) {
  var result;
  result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    };
  } else {
    return null;
  }
};

export function hexToRgba(color, opacity) {
  var array, rgb;
  rgb = hexToRgb(color);
  if (rgb != null) {
    array = [];
    array.push(rgb.r);
    array.push(rgb.g);
    array.push(rgb.b);
    array.push(opacity);
    return 'rgba(' + array.join(', ') + ')';
  } else {
    return null;
  }
};
