import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggler', 'input']

  connect() {
    this.toggle({ target: this.togglerTarget })
  }

  toggle({ target }) {
    this.inputTargets.forEach((element) => {
      this._toggleElement(element, target)
    })
  }

  _toggleElement(element, toggler) {
    if (this._enabledOn(element, toggler.value)) {
      this._toggleOn(element)
    } else {
      this._toggleOff(element)
    }
  }

  _enabledOn(element, value) {
    if (value === undefined) return false

    if (!element.dataset.inputTogglerOnFor) return false

    const onFor = element.dataset.inputTogglerOnFor.split(',')

    return onFor.includes(value)
  }

  _toggleOn(element) {
    element.disabled = false

    if (element.dataset.inputTogglerDisplayValue) {
      element.style.display = element.dataset.inputTogglerDisplayValue
    }
  }

  _toggleOff(element) {
    const initialDisplayValue = window.getComputedStyle(element).display
    if (initialDisplayValue != 'none') element.dataset.inputTogglerDisplayValue = initialDisplayValue

    element.style.display = 'none'

    element.disabled = true
  }
}
