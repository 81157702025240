import { Controller } from '@hotwired/stimulus'
import { setSelectedLoad } from '../helpers/truck_load';

export default class extends Controller {
  loader;

  static targets = ['table']

  connect() {
    const controller = this;
    controller.loader = document.querySelector('.page-loader');

    document.addEventListener('turbo:before-fetch-response', (event) => {
      controller.loader.style.display = 'none';
      Turbo.navigator.delegate.adapter.progressBar.hide();
    });

    document.addEventListener('turbo:before-fetch-request', (event) => {
      controller.loader.style.display = 'flex';
      Turbo.navigator.delegate.adapter.showProgressBar();
    });
  }

  tableTargetConnected(element) {
    setSelectedLoad();
  }

  sort(event) {
    const element = event.currentTarget;
    const href = element.href;
    const table = element.closest('.table');

    let sortingElements = table.getElementsByClassName('sorting-table');
    let elm;

    for (elm of sortingElements) {
      elm.classList.remove('desc');
      elm.classList.remove('asc');
    }
    if (href.includes('sort_order=asc')) {
      this.setSortDesc(element, href);
    } else {
      this.setSortAsc(element, href)
    }
  }

  setSortDesc(element, href) {
    let new_href = href.replace('sort_order=asc', 'sort_order=desc');
    element.classList.remove('asc');
    element.classList.add('desc');
    element.setAttribute('href', new_href);
  }

  setSortAsc(element, href) {
    let new_href = href.replace('sort_order=desc', 'sort_order=asc');
    element.classList.remove('desc');
    element.classList.add('asc');
    element.setAttribute('href', new_href);
  }
}
