import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [ 'title', 'date' ]

  print(event) {
    event.preventDefault()
    const pageTitle = document.title
    const title = this.titleTarget.textContent.toLowerCase().replace(/\s+/g, '-');
    let date = '';
    if (this.hasDateTarget) {
      const dateFieldValue = this.dateTarget.value
      date = dateFieldValue.toLowerCase().replace(/\s+/g, '-');
    } else {
      var today  = new Date();
      date = today.toISOString()
    }
    document.title = date + '-' + title + '.pdf';
    window.print();
    document.title = pageTitle
  }
}
