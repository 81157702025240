import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source']

  copy(event) {
    event.preventDefault()

    navigator.clipboard.writeText(this.sourceTarget.dataset.sourceValue)

    this.dispatch('copy', { detail: {
      message: 'Copied to clipboard' , wrapperClass: 'alert-info', icons: ['las', 'la-check']
    } })
  }
}
