import { Controller } from '@hotwired/stimulus'
import MarkerClusterer from '@googlemaps/markerclustererplus';
import * as Rails from '@rails/ujs';
import { getControllerByIdentifier } from 'helpers';
import { isAvalableLoadSelected } from '../helpers/truck_load';
import { mapDetailsType, insertGoogleMap } from '../helpers/maps';
import { get } from '@rails/request.js'
import { isNil, isEmpty } from 'lodash/lodash';

export default class extends Controller {
  map;
  google;
  markerClusterer;
  markers;
  markerPins;
  loader;
  bounds;
  warehouseLocations;
  mapDetailsType;
  searchLocationMarker;
  defaultMapCenter;
  effectsController;

  static targets = ['map', 'markerLocation', 'mapView', 'unitInfo', 'inputSearchBox', 'etaButton']
  static values = { pins: Object, type: String, mapKey: String }

  connect() {
    let controller = this;
    controller.effectsController = getControllerByIdentifier(controller, 'effects');

    controller.loader = document.querySelector('.page-loader');
    controller.markers = [];
    controller.warehouseLocations = [];
    controller.mapDetailsType = mapDetailsType();
    controller.markerPins = controller.pinsValue;
    controller.setUnitInfoBoxHeight()
    if (typeof (google) == 'undefined') {
      insertGoogleMap(controller.mapKeyValue);
    }
  }

  calculateEtaBox() {

  }
  mapTargetConnected() {
    const controller = this
    if (typeof (google) == 'undefined') {
      insertGoogleMap(controller.mapKeyValue);
    } else {
      controller.setMap();
    }
  }

  mapViewTargetConnected() {
    const controller = this
  }

  markerLocationTargetConnected(target) {
    const marker = this.markers.find(m => target.dataset.powerUnitId == m.powerUnitId);
    const image = this.markerPins[target.dataset.status] || this.markerPins['other']
    if (marker) {
      marker.setPosition({lat: Number(target.dataset.lat), lng: Number(target.dataset.lng)});
      marker.setIcon(image)
    }
    target.remove();
  }

  setMap() {
    // const US_BOUNDS = {
    //   east: -66.94,
    //   north: 49.38,
    //   south: 25.82,
    //   west: -124.39,
    // };
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(35, -96),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: 4,
      streetViewControl: false,
      zoomControl: false,
      minZoom: 3,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
    })

    this.setupSearchBox();
    this.initZoomControl(this.map);

    this.bounds = new google.maps.LatLngBounds();
    this.setupEvents();
  }

  initZoomControl(map) {
    document.querySelector('.zoom-control-in').onclick = function () {
      map.setZoom(map.getZoom() + 1);
    };

    document.querySelector('.zoom-control-out').onclick = function () {
      map.setZoom(map.getZoom() - 1);
    };

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.querySelector('.zoom-control')
    );
  }

  setupEvents() {
    let controller = this;
    controller.bounds = new google.maps.LatLngBounds();
    google.maps.event.addListenerOnce(this.map, 'idle', function() {
      if (controller.mapDetailsType.type == 'default') {
        controller.loadPins();
      } else if (controller.mapDetailsType.type == 'available') {
        controller.getAvailableLoadLocation(controller.mapDetailsType.selectedItemId)
      } else if (controller.mapDetailsType.type == 'enroute') {
        controller.getEnrouteLoadLocations(controller.mapDetailsType.selectedItemId)
      } else if (controller.mapDetailsType.type == 'units') {
        controller.getUnitLocations(controller.mapDetailsType.selectedItemId)
      }
    });
  }

  setupSearchBox() {
    let controller = this;

    const searchBox = new google.maps.places.SearchBox(controller.inputSearchBoxTarget);

    controller.map.controls[google.maps.ControlPosition.TOP_LEFT].push(controller.inputSearchBoxTarget);

    controller.setCalculateEtaButton();
    controller.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.f);

    controller.map.addListener("bounds_changed", () => {
      searchBox.setBounds(controller.map.getBounds());
    });
    let markers = [];

    searchBox.addListener("places_changed", () => {
      controller.etaButtonTarget.classList.remove('hidden')

      const places = searchBox.getPlaces();

      if (places.length == 0) { return; }

      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        const icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        this.searchLocationMarker = new google.maps.Marker({
          map: controller.map,
          icon,
          title: place.name,
          position: place.geometry.location,
        });

        markers.push(this.searchLocationMarker);
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        // controller.etaButtonTarget.style.display = 'block';
      });
      controller.map.fitBounds(bounds);
    });
  }

  setCalculateEtaButton() {
    let controller = this;
    controller.map.controls[google.maps.ControlPosition.TOP_LEFT].push(controller.etaButtonTarget);

    controller.etaButtonTarget.addEventListener('click', () => {
      let dispatchesController = getControllerByIdentifier(controller, 'dispatches');
      if (controller.searchLocationMarker != null) {
        dispatchesController.getUnitsByEta({ lat: controller.searchLocationMarker.position.lat(), lng: controller.searchLocationMarker.position.lng() });
        dispatchesController.removeRowSelection();
        controller.loadPins();

        controller.bounds.extend(controller.searchLocationMarker.position)
        controller.map.fitBounds(controller.bounds);
      }
    });
  }

  clearSearchBox() {
    let controller = this;
    controller.inputSearchBoxTarget.value = ''

    if ((typeof(controller.searchLocationMarker) !== 'undefined') && (controller.searchLocationMarker !== null)) {
      controller.searchLocationMarker.setMap(null);
      controller.searchLocationMarker = null;
      controller.etaButtonTarget.classList.add('hidden')
    }
  }

  loadPins() {
    let controller = this;
    controller.loader.style.display = 'flex';

    let url = '/maps/all_units_location';
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      success: (response) => {
        controller.refreshMap(response.power_units);
        controller.loader.style.display = 'none';
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR);
        controller.loader.style.display = 'none';
      }
    })
  }

  refreshMap(data) {
    let controller = this;

    controller.removeMarkers();
    controller.addMarkers(data);

    controller.markerClusterer = new MarkerClusterer(controller.map, controller.markers, {
      maxZoom: null,
      gridSize: 20,
      styles: this.clusterStyle(),
      clusterClass: 'custom-clustericon'
    });
  }

  addMarkers(data) {
    let controller = this
    controller.markers = [];

    data.forEach(unit => {
      try {
        if (_.isNil(unit) || _.isNil(unit.lat) || _.isNil(unit.lng)) { return }
        let latLng = new google.maps.LatLng(
          unit.lat,
          unit.lng
        );
        let image = controller.markerPins[unit.status] || controller.markerPins['other']
        let markerImage = new google.maps.MarkerImage(
          image,
          new google.maps.Size(38, 38)
        );
        let marker = new google.maps.Marker({
          position: latLng,
          icon: markerImage,
          map: controller.map,
          powerUnitId: unit.id,
        });

        marker.infoWindow = new google.maps.InfoWindow({
          content: `<div id=info_window_pu_${unit.id}></div>`,
          disableAutoPan: true,
        });

        controller.bounds.extend(latLng);
        controller.markers.push(marker);
       } catch (error) {
          console.error(error)
          controller.effectsController.flash( { detail: {
            message: 'Unexpected error occurred, please contact your Administrator' , wrapperClass: 'alert-error', icons: ['las', 'la-exclamation-triangle']
          }})
       }
    });

    controller.markers.forEach(marker => {
      marker.addListener('mouseover', () => {
        marker.infoWindow.open(controller.map, marker);
        controller.infoWindowContent(marker.powerUnitId)
      })
      marker.addListener('mouseout', () => {
        marker.infoWindow.close(controller.map, marker);
      })

      marker.addListener('click', () => {
        controller.map.panTo(marker.getPosition());
        controller.addPowerUnitInfoBox(marker.powerUnitId);
        controller.selectPowerUnit(marker.powerUnitId);
      })
    })
    if (data.length > 0) {
      controller.map.fitBounds(controller.bounds);
    } else {
      controller.bounds.extend(new google.maps.LatLng(35, -96));
    }
  }

  infoWindowContent(unitId) {
    let dData =  new FormData();
    dData.set('pu_id', unitId)
    get('/maps/last_unit_location', {
      query: dData,
      responseKind: 'turbo-stream'
    })
  }

  addPowerUnitInfoBox(powerUnitId) {
    let controller = this
    let url = '/dispatches/power_unit';
    let dData =  new FormData();
    dData.set('id', powerUnitId);
    controller.loader.style.display = 'flex';
    Rails.ajax({
      type: 'POST',
      dataType: 'html',
      url: url,
      data: dData,
      success: (response) => {
        let responseHtml = response.documentElement.querySelector('.info-wrapper')
        controller.unitInfoTarget.replaceChildren(responseHtml)
        controller.unitInfoTarget.style.width = '44%';
        controller.unitInfoTarget.style.display = 'block'
        controller.loader.style.display = 'none';
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR);
        controller.loader.style.display = 'none';
      }
    })
  }

  showPowerUnitInfoBox(event) {
    let controller = this;
    let powerUnitId = event.currentTarget.dataset.id;
    controller.resetMarkersOpacity()
    let marker  = controller.markers.find(obj => obj.powerUnitId == powerUnitId )
    controller.highlightMarker(marker)
    controller.selectPowerUnit(powerUnitId)
    controller.addPowerUnitInfoBox(powerUnitId);
    controller.setUnitInfoBoxHeight()
  }

  closePowerUnitInfoBox() {
    let controller = this;
    controller.unitInfoTarget.style.display = 'none'
    controller.unitInfoTarget.style.width = '0px'
    controller.resetMarkersOpacity()
  }

  setUnitInfoBoxHeight() {
    const controller = this
    if (!controller.hasUnitInfoTarget) { return }

    controller.unitInfoTarget.style.height = `${controller.mapViewTarget.offsetHeight - 2}px`;
  }
  highlightMarker(marker) {
    let controller = this;
    if (marker) {
      controller.map.panTo(marker.getPosition());
      marker.setAnimation(google.maps.Animation.BOUNCE);
      setTimeout(function(){ marker.setAnimation(null); }, 300);
      marker.setOpacity(0.7);
    }
  }

  resetMarkersOpacity() {
    let controller = this;
    controller.markers.forEach((marker) => {
      marker.setOpacity(1);
    });
  }

  selectPowerUnit(powerUnitId) {
    let powerUnitsTable = document.getElementById('units_data')
    let unitsRows = powerUnitsTable.querySelectorAll('tr .power-unit');
    let unit = powerUnitsTable.querySelector("[data-id='" + powerUnitId + "']");
    unitsRows.forEach((unit) => {
      unit.style.fontWeight = 'normal';
    });
    if (unit) {
      unit.style.fontWeight = 'bold';
    }

  }
  removeMarkers() {
    let controller = this;
    if (controller.markerClusterer) {
      controller.markerClusterer.clearMarkers();
    }
    controller.markers.forEach(marker => {
      marker.setMap(null);
    })

    controller.warehouseLocations.forEach(marker => {
      marker.setMap(null);
    })

    // controller.bounds = new google.maps.LatLngBounds();
  }

  clearClusters(e) {
    e.preventDefault();
    e.stopPropagation();
    this.markerClusterer.clearMarkers();
  }

  clusterStyle() {
    let style = [
      {
        width: 64,
        height: 30,
        className: "custom-clustericon",
      }
    ]
    return style
  }

  truckLoadAvailableLocation(event) {
    let controller = this;
    let loadId = event.currentTarget.dataset.itemId
    controller.unitInfoTarget.style.display = 'none'
    if (isAvalableLoadSelected()) {
      controller.getAvailableLoadLocation(loadId)
    } else {
      controller.loadPins()
    }
  }

  onAvailabaleSelect() {
    let controller = this;
    let loadId = ''
    if (isAvalableLoadSelected()) {
      loadId = localStorage.getItem('selectedItemId')
      controller.getAvailableLoadLocation(loadId)
    } else {
      controller.loadPins()
    }
  }

  getAvailableLoadLocation(loadId) {
    let controller = this;
    controller.loader.style.display = 'flex';

    let url = '/maps/available_loads_location';
    let dData =  new FormData();

    dData.set('id', loadId);
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      data: dData,
      success: (response) => {
        controller.refreshMap(response.power_units)
        controller.setWarehousesLoacations([response.pickup_locations[0]])
        controller.loader.style.display = 'none';
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR)
        controller.loader.style.display = 'none';
      }
    })
  }

  truckLoadEnrouteLocations(event) {
    let controller = this;
    let loadId = event.currentTarget.dataset.itemId;
    let truckLoadRow = event.currentTarget.closest('tr');
    let currentLoadIdSelected = truckLoadRow.classList.contains('selected');

    controller.unitInfoTarget.style.display = 'none'

    if (currentLoadIdSelected) {
      controller.loadPins()
    } else {
      controller.getEnrouteLoadLocations(loadId)
    }
  }

  getEnrouteLoadLocations(loadId) {
    let controller = this;

    controller.loader.style.display = 'flex';

    let url = '/maps/enroute_loads_location';
    let dData =  new FormData();

    dData.set('id', loadId);

    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      data: dData,
      success: (response) => {
        controller.refreshMap(response.power_units)
        controller.setWarehousesLoacations(response.pickup_locations)
        controller.setWarehousesLoacations(response.delivery_locations)
        controller.loader.style.display = 'none';
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR)
        controller.loader.style.display = 'none';
      }
    })
  }

  unitLocations(event) {
    let controller = this;
    let unitId = event.currentTarget.dataset.itemId;
    let truckLoadRow = event.currentTarget.closest('tr');
    let currentLoadIdSelected = truckLoadRow.classList.contains('selected');
    controller.unitInfoTarget.style.display = 'none'
    if (currentLoadIdSelected) {
      controller.loadPins()
    } else {
      controller.getUnitLocations(unitId)
    }
  }

  getUnitLocations(unitId) {
    let controller = this;

    controller.loader.style.display = 'flex';

    let url = '/maps/unit_locations';
    let dData =  new FormData();

    dData.set('id', unitId);

    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      data: dData,
      success: (response) => {
        controller.refreshMap(response.power_units)
        controller.setWarehousesLoacations(response.pickup_locations)
        controller.setWarehousesLoacations(response.delivery_locations)
        controller.loader.style.display = 'none';
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR)
        controller.loader.style.display = 'none';
      }
    })
  }

  setWarehousesLoacations(data) {
    let controller = this;

    const alphabet = Array.from(Array(26), (e, i) => String.fromCharCode(i + 97));

    data.forEach((location, i) => {
      try {
        let latLng = new google.maps.LatLng(
          location.lat,
          location.lng
        );

        let img = location.type == 'receiver' ? controller.markerPins.delivery_location : controller.markerPins.pickup_location
        let markerImage = new google.maps.MarkerImage(
          img,
          new google.maps.Size(32, 42)
        );

        let blue = '#1986da'
        let lightBlue = '#18c3e9'
        let green = '#0d8643'
        let pink = '#ed1d71'
        let red = '#e50027'
        let typeColor = location.type == 'receiver' ? red : green
        let color = data.length > 1 ? '#000000' : typeColor
        let cssClass = data.length > 1 ? 'mark-lable' : 'warehouse-lable'
        let text = data.length > 1 ? alphabet[i].toUpperCase() : location.name

        let marker = new google.maps.Marker({
          position: latLng,
          icon: markerImage,
          map: controller.map,
          label: { color: color, fontWeight: 'bold', fontSize: '14px', text: text, className: cssClass }
        });
        let content = '<div class="info-window">'
            content += `<h3>${location.name}</h3>`
        if (!location.verified) {
          content += '<i class="fas fa-exclamation-triangle"></i></div>'
        } else {
          content += '</div>'
        }

        marker.infoWindow = new google.maps.InfoWindow({
          content: content,
        });

        controller.bounds.extend(latLng);
        controller.warehouseLocations.push(marker);

        controller.warehouseLocations.forEach(marker => {
          marker.addListener('mouseover', () => {
            marker.infoWindow.open(controller.map, marker);
          })
          marker.addListener('mouseout', () => {
            marker.infoWindow.close(controller.map, marker);
          })

          marker.addListener('click', () => {
            marker.infoWindow.open(controller.map, marker);
            controller.map.setZoom(9);
            controller.map.panTo(marker.getPosition());
          })
        })

        if (controller.warehouseLocations.length > 1) {
          controller.map.fitBounds(controller.bounds);
        } else {
          let latLng = new google.maps.LatLng(location.lat,location.lng);

          controller.map.panTo(controller.warehouseLocations[0].position);
        }
      } catch (error) {
        console.error(error)
        controller.effectsController.flash( { detail: {
          message: 'Unexpected error occurred, please contact your Administrator' , wrapperClass: 'alert-error', icons: ['las', 'la-exclamation-triangle']
        }})
      }
    })
  }
}
