import { Controller } from '@hotwired/stimulus'

import Huebee from 'huebee'

export default class extends Controller {
  static targets = ['input', 'target']

  inputTargetConnected(target) {
    const controller = this
    let hueb = new Huebee(controller.inputTarget, {
      saturations: 1,
      shades: 0,
      customColors: controller.customColors()
    })

    hueb.on('change', function( color, hue, sat, lum ) {
      controller.inputTarget.dispatchEvent(new Event('change'));
    })
  }

  customColors() {
    return ['#ac0002', '#ff0006', '#ff9400', '#f4ff00', '#0f0', '#4b72e8', '#5700ff', '#be00ff', '#f0f', '#000', '#fff']
  }
}
