import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'title', 'removable', 'unsetable', 'removeClass']
  static values = { formAction: String, title: String }
  static classes = ['removable']

  clone() {
    this._updateForm(this.formTarget, this.formActionValue)

    this._updateTitle(this.titleTarget, this.titleValue)

    this._unset(this.unsetableTargets)

    this._remove(this.removableTargets)

    this._removeClass(this.removeClassTargets, this.removableClass)
  }

  _updateForm(form, action) {
    form.action = action
    form.querySelector('[name="_method"]')?.remove()
  }

  _updateTitle(element, title) {
    element.innerHTML = title
  }

  _unset(elements) {
    elements.forEach(function(el) {
      // Handle SlimSelect inputs
      if (el.slim) {
        el.slim.set('')
      } else {
        el.value = ''
      }
    })
  }

  _remove(elements) {
    elements.forEach(function(el) { el.remove() })
  }

  _removeClass(elements, className) {
    elements.forEach(function(el) { el.classList.remove(className) })
  }
}
