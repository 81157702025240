import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['attachButton', 'itemId', 'template', 'field']
  static values = {
    max: Number
  }

  itemIdTargetConnected(target) {
    let fields = this.fieldTargets;
    if (fields.length == 0 && this.maxValue > 0 ) {
      this.attachButtonTarget.click();
      fields = this.element.querySelectorAll("[data-association-fields-target='field']")
    }
    this.setOption(fields, target);
    if (this.hasTemplateTarget) {
      const fieldsInTemplate = this.templateTarget.content.querySelectorAll("[data-association-fields-target='field']");
      this.setOption(fieldsInTemplate, target);
    }
  }

  setOption(fields, target) {
    const text = target.dataset.name
    const value = target.dataset.id

    fields.forEach((item, i) => {
      let option = new Option(text, value, false, item.value == '');
      item.add(option);
    });
  }
}
