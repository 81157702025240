import Dropzone from "dropzone";
import { Controller } from '@hotwired/stimulus'
import * as Rails from '@rails/ujs';
import { DirectUpload } from '@rails/activestorage';
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter,
  getControllerByIdentifier
} from 'helpers';

export default class extends Controller {
  static targets = ['input', 'box', 'submitForm'];

  dropZone = null;

  connect() {
    this.dropZone = createDropZone(this, this.boxTarget);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false;
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    let controller = this
    const truckLoadController = getControllerByIdentifier(this, 'truck-load');

    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("success", (file, response) => {
      controller.createPreviewLink(file);
      if (controller.hasSubmitFormTarget) {
        Rails.fire(controller.inputTarget.closest('form'), 'submit');
      }
      let anchor = file.previewElement.querySelector("[data-controller='preview']")
      if ((controller.element.dataset['new'] == 'true') && new RegExp('pdf').test(file.type)) {
        let url = '/parse_rc_pdf'
        let dData =  new FormData();
        dData.set('file', file);
        Rails.ajax({
          type: 'POST',
          dataType: 'json',
          url: url,
          data: dData,
          success: (response) => {
            anchor.click()
            localStorage.setItem('fileName', anchor.dataset.fileName);

            if (response.results) {
              let shippers = response.results.shippers.map((obj, i)=> ({ ...obj, idx : i }))
              let receivers = response.results.receivers.map((obj, i)=> ({ ...obj, idx : i }))
              if (response.results.customers) {
                truckLoadController.setCustomersQueueData(response.results.customers)
              }
              truckLoadController.setShippersQueueData(shippers);
              truckLoadController.setReceiversQueueData(receivers);
              truckLoadController.fromPDF(response.results);
            }
          },
          error: (XHR, textStatus, errorThrown) => {
            console.log(XHR)
          }
        })
      }
    });
  }

  createPreviewLink(file) {
    let type = (new RegExp('^image|img/.*$').test(file.type)) ? '' : 'preview';
    let anchor = file.previewElement.querySelector("[data-controller]")

    anchor.dataset.previewTypeValue = type
    anchor.dataset.previewUrlValue = URL.createObjectURL(file)
    anchor.dataset.uuid = file.upload.uuid
    anchor.dataset.fileName = file.upload.filename
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.element.dataset.maxFiles || 1;
  }

  get maxFileSize() {
    return this.element.dataset.maxFileSize || 256;
  }

  get acceptedFiles() {
    const defaultAcceptedFiles = 'image/jpeg,.jpg,.jpeg,image/tiff,.tiff,.tif,image/gif,.gif,image/png,.png,application/pdf,.pdf'
    return this.data.get("acceptedFiles") || defaultAcceptedFiles;
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || false;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller, target) {
  return new Dropzone(target, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    previewTemplate: document.getElementById('preview_template').innerHTML
  });
}
