import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'
import isNil from 'lodash/lodash';

export default class extends Controller {
  loader;

  static targets = ['content', 'table', 'tableRow',
                    'noDataMessage', 'menuItem']

  connect() {
    this.loader = document.querySelector('.page-loader');
  }

  scrollTo(event) {
    var fullUrl, parts, offset, trgt;
    event.preventDefault();
    const element = event.currentTarget;
    var box = element.closest('.overlay-box')
    fullUrl = element.getAttribute('href');
    parts = fullUrl.split("#");
    trgt = parts[1];
    offset = document.querySelector("#" + trgt).offsetTop;
    let elementToScroll = _.isNil(box) ? window : box
    elementToScroll.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  }

  openTasksBox(event) {
    const controller = this
    let label = controller.element.querySelector('#taskas_box')
    let button = label.querySelector('.show-content-button')
    if (controller.hasContentTarget) {
      controller.contentTarget.classList.toggle('hidden')
      if (controller.contentTarget.classList.contains('hidden')) {
        button.click();
      }
    }
    controller.scrollTo(event)
  }

  tableRowTargetConnected() {
    this.tableTarget.classList.remove('hidden')
    if (this.hasNoDataMessageTarget) {
      this.noDataMessageTarget.remove()
    }
  }

  async markAsCompleted(event) {
    let controller = this
    controller.loader.style.display = 'flex';

    let itemId = event.currentTarget.dataset.itemId
    let url = `/tasks/${itemId}/mark_as_completed`

    const response = await post(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      controller.loader.style.display = 'none';
    } else {
      controller.loader.style.display = 'none';
      console.log(response.statusCode);
    }
  }

  menuItemTargetConnected(target) {
    if (this.hasNoDataMessageTarget) {
      this.noDataMessageTarget.classList.add('hidden')
    }
  }

  menuItemTargetDisconnected(target) {
    if (this.hasMenuItemTarget) { return }

    if (this.hasNoDataMessageTarget) {
      this.noDataMessageTarget.classList.remove('hidden')
    }
  }
}