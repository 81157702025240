import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'form' ]

  load(event) {
    const { target } = event
    if (!target.value) { return }

    get(`/filters/${target.value}`, {
      // headers: { 'Turbo-Frame': target.dataset.targetFrame },
      responseKind: 'turbo-stream'
    })
  }
}
