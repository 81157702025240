// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus'
import NestedForm from 'stimulus-rails-nested-form'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start()
const context = require.context(".", true, /\.js$/)

application.load(definitionsFromContext(context))
application.register('nested-form', NestedForm)
