import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  loader;

  static targets = ['noDataMessage', 'menuItem']

  connect() {
    this.loader = document.querySelector('.page-loader');
  }

  async markAsRead(event) {
    let controller = this
    controller.loader.style.display = 'flex';

    let itemId = event.currentTarget.dataset.itemId
    let url = `/notifications/${itemId}/mark_as_read`

    const response = await post(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      controller.loader.style.display = 'none';
    } else {
      controller.loader.style.display = 'none';
      console.log(response.statusCode);
    }
  }

  menuItemTargetConnected(target) {
    if (this.hasNoDataMessageTarget) {
      this.noDataMessageTarget.classList.add('hidden')
    }
  }

  menuItemTargetDisconnected(target) {
    if (this.hasMenuItemTarget) { return }

    if (this.hasNoDataMessageTarget) {
      this.noDataMessageTarget.classList.remove('hidden')
    }
  }
}