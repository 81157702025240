
export function dateOptions() {
  return {
    altInput: true,
    dateFormat: "Y-m-d",
    altFormat: "m-d-Y",
    allowInput: true,
  }
}

export function timeOptions() {
  return {
    altInput: true,
    enableTime: true,
    noCalendar: true,
    time_24hr: true,
    dateFormat: 'H:i',
    altFormat: 'H:i',
    allowInput: true,
  }
}