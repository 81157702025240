import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];
  static values = { url: String };

  toggleContent(event) {
    const controller = this
    let button = event.currentTarget
    let icon = button.querySelector('i.indicator.icon');
    if (controller.hasContentTarget) {
      if (controller.contentTarget.classList.contains('hidden')) {
        controller.contentTarget.classList.remove('hidden');
        button.setAttribute('aria-label', 'Collapse');
        icon.classList.remove('collapsed')
        icon.classList.add('expanded')
      } else {
        controller.contentTarget.classList.add('hidden');
        button.setAttribute('aria-label', 'Expand');
        icon.classList.remove('expanded')
        icon.classList.add('collapsed')
      }
    } else {
      fetch(controller.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);
          controller.element.appendChild(fragment);
        });
      button.setAttribute('aria-label', 'Collapse');
      icon.classList.remove('collapsed')
      icon.classList.add('expanded')
    }
  }

  disconnect() {
    if (this.hasContentTarget) {
      this.contentTarget.remove();
    }
  }
}
