import { Controller } from '@hotwired/stimulus'
import Chartkick from 'chartkick'

export default class extends Controller {
  static targets = ['chart']

  connect() {
    if (this.hasChartTarget) {
      this._apply(this.chartTarget)
    }
  }

  chartTargetConnected(element) {
    this._apply(element)
  }

  _apply(element) {
    const type = element.dataset.type
    if (type == 'line') {
      this.lineChart(this.chartTarget)
    } else if (type == 'pie') {
      this.pieChart(this.chartTarget)
    }
  }

  chartData() {
    if (!this.hasChartTarget) { return {} }
    return JSON.parse(this.chartTarget.dataset.base)
  }

  chartOptions() {
    if (!this.hasChartTarget) { return {} }
    let options = {}
    if (this.chartTarget.dataset.option) {
      options = JSON.parse(this.chartTarget.dataset.option)
    }
    options['empty'] = 'No data available'
    return  options
  }

  lineChart(element) {
    let data = this.chartData()
    let option = this.chartOptions()

    new Chartkick.LineChart(this.chartTarget.id, data, option)
  }

  pieChart(element) {
    let data = this.chartData()
    let option = this.chartOptions()
    new Chartkick.PieChart(this.chartTarget.id, data, option)
  }
}
