import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [ 'from', 'to', 'field' ]
  from;
  to;

  connect() {}

  fromTargetConnected(element) {
    const controller = this;
    controller.from = new Sortable(element, {
      group: 'shared',
      ghostClass: 'ghost',
      group: {
         name: 'shared',
      },
      sort: false,
      animation: 150,
      revertClone: true,
      chosenClass: 'chosen'
    })
  }

  toTargetConnected(element) {
    const controller = this;

    controller.to = new Sortable(element, {
      group: {
        name: 'shared',
      },
      ghostClass: 'ghost',
      chosenClass: 'chosen',
      animation: 150,
      sort: true,
      onSort: (evt) => {
        controller.changeList()
      },
    })
  }

  cloneItem(event) {
    const controller = this;
    let item  = event.currentTarget.closest('.item')
    let clone = item.cloneNode(true);
    clone.dataset.action = ''
    this.toTarget.append(clone);
    item.remove();
    controller.changeList();
  }

  removeItem(event) {
    const controller = this;
    let item  = event.currentTarget.closest('.item')
    let clone = item.cloneNode(true);
    clone.dataset.action = 'dblclick->sortable#cloneItem'
    this.fromTarget.prepend(clone);
    item.remove();
    controller.changeList();
  }

  changeList() {
    const controller = this;
    let items = controller.toTarget.children;

    controller.fieldTarget.innerHTML = ''
    items.forEach((item, i) => {
      let option = new Option(item.textContent, item.dataset.id, true, true)
      controller.fieldTarget.add(option)
    })
    controller.fieldTarget.dispatchEvent(new Event('change'))
  }
}
