import { Controller } from '@hotwired/stimulus'
import * as Rails from '@rails/ujs';
import { get, post } from '@rails/request.js'
import { fontColor } from '../helpers/colors';
import { Calendar } from '@fullcalendar/core';
import { DateTime } from 'luxon';
import adaptivePlugin from '@fullcalendar/adaptive';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { showOverlay, hideOverlay } from '../helpers/overlays';

import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['calendar', 'lastTl']
  static values = { newTlUrl: String }

  calendar;
  loader;

  connect() {
    const controller = this;
    const dialogController = controller.dialogController(controller);
    controller.loader = document.querySelector('.page-loader');
    if (!this.hasCalendarTarget) { return }

    let defaultDate = null;
    const startAtSet = (localStorage.getItem('calendarStartAt') !== null);
    if (startAtSet) {
      defaultDate = localStorage.getItem('calendarStartAt')
    }
    let calendarEl = this.calendarTarget;
    controller.calendar = new Calendar(calendarEl, {
      timeZone: 'UTC',
      plugins: [ adaptivePlugin, interactionPlugin, resourceTimelinePlugin ],
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      // editable: true, // enable draggable events
      // aspectRatio: 1.5,
      initialDate: defaultDate,
      scrollTime: '00:00', // undo default 6am scrollTime
      nowIndicator: true,
      slotDuration: '02:00:00',
      slotLabelInterval: '02:00',
      resourceAreaWidth: '20%',
      slotMinWidth: 15,
      selectable: true,
      slotLabelFormat: [
        { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
        {
          hour12: false,
          hour: 'numeric',
        },
      ],
      headerToolbar: {
        left: 'today prev,next',
        center: 'title',
        right: 'resourceTimelineDay,resourceTimelineWeek'
      },
      initialView: 'resourceTimelineWeek',
      views: {
        resourceTimelineWeek: {
          type: 'resourceTimeline',
          duration: { days: 7 },
          buttonText: 'Week'
        }
      },
      buttonText: {
        today: 'Today',
        day: 'Day'
      },
      resourceAreaHeaderContent: 'Units',
      resourceAreaColumns: [
        {
          field: 'name',
          headerContent: 'Units',
          width: '25%'
        },
        {
          field: 'drivers',
          headerContent: 'Drivers'
        }
      ],
      resources: (fetchInfo, successCallback, failureCallback) => {
        Rails.ajax({
          type: 'POST',
          dataType: 'json',
          url: ' /dispatch_calendars/fetch_power_units',
          success: (response) => {
            successCallback(response);
          },
          error: (XHR, textStatus, errorThrown) => {
            console.log(XHR)
          }
        })
      },
      resourceLabelDidMount: (info) => {
        var questionMark = document.createElement('strong');
        if (info.resource.extendedProps.status == 'subunit') {
          questionMark.innerText = info.resource.extendedProps.status;
        }
        // questionMark.innerText = ' (?) ';

        info.el.querySelector('.fc-datagrid-cell-main')
          .appendChild(questionMark);
        var tooltip = tippy(questionMark, {
          content: info.resource.extendedProps.status + '!!!',
          // trigger: 'hover',
        });

        // var id = document.createElement('strong');
        // id.innerText = info.resource.id
        // info.el.querySelector('.fc-datagrid-cell-main')
        //   .appendChild(id);
      },
      events: (fetchInfo, successCallback, failureCallback) => {
        let dData =  new FormData();
        dData.set('start', fetchInfo.startStr);
        dData.set('end', fetchInfo.endStr);
        Rails.ajax({
          type: 'POST',
          dataType: 'json',
          data: dData,
          url: ' /dispatch_calendars/fetch_truck_loads',
          success: (response) => {
            response.forEach(element => {
              element.color = controller.tlColors()[element.color_status]
              element.textColor = fontColor(element.color)
            })
            successCallback(response)
          },
          error: (XHR, textStatus, errorThrown) => {
            console.log(XHR)
          }
        })
      },
      loading: (isLoading) => {
        if (isLoading) {
          controller.loader.style.display = 'flex';
        } else {
          controller.loader.style.display = 'none';
        }
      },
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        timeZone: 'UTC'
      },
      eventContent: (arg) => {
        let title = document.createElement('strong')
        let text = document.createTextNode(arg.event.title);
        title.appendChild(text)
        let icon = document.createElement('i')
        let list = ['fas', 'fa-map-marker-alt', 'icon', 'receiver']
        icon.classList.add(...list)
        let destination = document.createElement('span')
        let destinationText = document.createTextNode(arg.event.extendedProps.destination);
        destination.appendChild(destinationText)
        let arrayOfDomNodes = [ title, icon, destination ]
        return { domNodes: arrayOfDomNodes }
      },
      eventClick: (info) => {
        showOverlay('truck_load')
        let path = `/truck_loads/${info.event.id}/edit`;
        let frameTarget = document.getElementById('truck_load')
        frameTarget.src = path
      },
      eventMouseEnter: (info) => {
        var content = '<div>'
        let startInTz = DateTime.fromISO(info.event.startStr, { zone: info.event.extendedProps.start_tz })
        let endInTz = DateTime.fromISO(info.event.endStr, { zone: info.event.extendedProps.end_tz })
        let start = startInTz.toFormat('MM-dd-yyyy HH:mm ZZZZ')
        var end = ''
        if (info.event.end) {
          end = ` - ${endInTz.toFormat('MM-dd-yyyy HH:mm ZZZZ')}`
        }
        content += `<p>${start}${end}</p>`
        content += `<p>WO: ${info.event.extendedProps.wo}</p>`
        if (typeof info.event.extendedProps.description != 'undefined' && info.event.extendedProps.description) {
          content += `<p>${info.event.extendedProps.description}</p>`
        }
        content += '</div>'
        var tooltip = tippy(info.el, {
          content: content,
          allowHTML: true,
          // trigger: 'hover',
        });
      },
      eventMouseLeave: (info) => {
      },
      select: (info) => {
        showOverlay('truck_load')
        let puId = info.resource._resource.id
        let tl = controller.tlData(info)
        let tlData = JSON.stringify(tl)
        let dData =  new FormData();
        dData.set('template', tlData)
        dData.set('pu_id', puId)
        get(controller.newTlUrlValue, {
          query: dData,
          responseKind: 'turbo-stream'
        })
        document.addEventListener("turbo:submit-end", (event) => {
          console.log(event.detail)
        })
      }
    });

    controller.calendar.render();
    controller.calendar.setOption('contentHeight', controller.contentHeight());

    controller.calendar.on('datesSet', function(dateInfo) {
      localStorage.setItem('calendarStartAt', dateInfo.startStr)
    });
  }

  lastTlTargetConnected(target) {
    let controller = this;
    let start = new DateTime.fromSQL(target.dataset.start, { zone: "UTC" }).toISO()
    let end = new DateTime.fromSQL(target.dataset.end, { zone: "UTC" }).toISO()
    let tlEvent = controller.calendar.getEventById(target.dataset.id)

    if (tlEvent && (!tlEvent._def.resourceIds.includes(target.dataset.id))) {
      tlEvent.remove()
      tlEvent = null
    }
    if (tlEvent) {
      tlEvent.setStart(start)
      tlEvent.setEnd(end)
    } else {
      let newEvent = controller.calendar.addEvent({
        title: target.dataset.title,
        start: start,
        end: end,
        resourceId: target.dataset.resourceid,
        id: target.dataset.id
      })
      tlEvent = newEvent
    }
    tlEvent.setExtendedProp('description', target.dataset.description)
    tlEvent.setExtendedProp('wo', target.dataset.wo)
    tlEvent.setExtendedProp('start_tz', target.dataset.startTz)
    tlEvent.setExtendedProp('end_tz', target.dataset.endTz)
    tlEvent.setProp('color', controller.tlColors()[target.dataset.colorStatus])
    tlEvent.setProp('textColor', fontColor(controller.tlColors()[target.dataset.colorStatus]))
    tlEvent.setExtendedProp('destination', target.dataset.destination)
  }

  dialogController(controller) {
    return controller.application.getControllerForElementAndIdentifier(document.querySelector('div#dispatch_calendars_page'), "dialog")
  }

  tlColors() {
    return { late: '#ff9400', today: '#d43f3a', tomorrow: '#f4ff00', 'day-after-tomorrow': '#59b3d6', 'three-plus-days': '#3874eb' }
  }

  contentHeight() {
    let header = document.querySelector('header')
    let mainMenu = document.getElementById('main_menu')
    let contentHeader = document.querySelector('.content-header')
    let toolbarHeader = document.querySelector('.fc-header-toolbar')
    let height = header.offsetHeight + mainMenu.offsetHeight + contentHeader.offsetHeight + toolbarHeader.offsetHeight + 20
    window.innerHeight;
    return window.innerHeight - height
  }

  tlData(info) {
    let tlData = {
      truck_load_warehouses_attributes: {
        0: {
          warehouse_start_at: info.start,
          // warehouse_end_at: info.start,
        },
        1: {
          warehouse_start_at: info.end,
          // warehouse_end_at: info.end,
        }
      },
      truck_load_power_units_attributes: {
        0: { power_unit_id: info.resource._resource.id }
      }
    }
    return tlData
  }
}
