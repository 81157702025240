import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr'
import Inputmask from 'inputmask'
import { DateTime } from 'luxon';
import { isNil, isNull, isEmpty } from 'lodash/lodash';
import { dateOptions, timeOptions } from '../helpers/datetime_config';
export default class extends Controller {
  static targets = ['endAtTime', 'appointment', 'startAtTimeLabel',
                    'date', 'time', 'endTime', 'timeZone', 'startAtField', 'endAtField'];
  static values = { startDate: String, endDate: String }

  dateInTZ;
  startTimeInTZ;
  endTimeInTZ;
  defaultTime = '12:00'

  connect() {
    this.setFieldsDateTime()
  }

  timeTargetConnected(target) {
    target.addEventListener('change', (event) => {
      this.setDates()
    });
  }

  endTimeTargetConnected(target) {
    target.addEventListener('change', (event) => {
      this.setDates()
    });
  }

  startDateValueChanged() {
    this.setTimeZoneFields()
  }

  setFieldsDateTime() {
    const controller = this
    if (controller.appointmentTarget.checked) {
      controller.endAtTimeTarget.classList.add('hidden')
      controller.startAtTimeLabelTarget.innerText = 'Time'
    } else {
      controller.endAtTimeTarget.classList.remove('hidden')
      controller.startAtTimeLabelTarget.innerText = 'From'
    }
  }

  setTimeZoneFields() {
    const controller = this
    let timeZone = this.timeZoneTarget.value
    if (!_.isEmpty(this.startDateValue) && !_.isEmpty(timeZone)) {
      let startDate = DateTime.fromSQL(this.startDateValue, { zone: 'utc' })
      let satartDateInTZ = startDate.setZone(timeZone)
      this.startTimeInTZ = satartDateInTZ.toLocaleString(DateTime.TIME_24_SIMPLE)
      this.dateInTZ = satartDateInTZ.toFormat('yyyy-MM-dd')
      this.dateTarget.value = this.dateInTZ
      this.timeTarget.value = this.startTimeInTZ

      let endDate
      if (this.endDateValue) {
        endDate = DateTime.fromSQL(this.endDateValue, { zone: 'utc' });
        let endDateInTZ = endDate.setZone(timeZone)
        this.endTimeInTZ = endDateInTZ.toLocaleString(DateTime.TIME_24_SIMPLE)
        this.endTimeTarget.value = this.endTimeInTZ
      }
    }
    let dateFP = flatpickr(this.dateTarget, dateOptions())

    let timeFP = flatpickr(this.timeTarget, timeOptions())
    timeFP.config.onValueUpdate.push((selectedDates, dateStr, instance) => {
      controller.timeTarget.dispatchEvent(new Event('change'));
    } );

    let endTimeFP = flatpickr(this.endTimeTarget, timeOptions())
    endTimeFP.config.onValueUpdate.push((selectedDates, dateStr, instance) => {
      this.endTimeTarget.dispatchEvent(new Event('change'));
    });
    this.dateMask(dateFP.altInput)
    this.timeMask(timeFP.altInput)
    this.timeMask(endTimeFP.altInput)
  }

  onAppointmentChange(event) {
    this.setFieldsDateTime()
  }

  setDates() {
    let timeZone = this.timeZoneTarget.value
    let startTime = this.timeTarget.value || this.defaultTime
    let endTime = this.endTimeTarget.value
    let startDate = DateTime.fromSQL(`${this.dateTarget.value} ${startTime}`, { zone: timeZone }).toUTC().toSQL();
    this.startAtFieldTarget.value = startDate
    let endDate = null
    if (endTime) {
      endDate = DateTime.fromSQL(`${this.dateTarget.value} ${endTime}`, { zone: timeZone }).toUTC().toSQL();
      this.endAtFieldTarget.value = endDate
    }
  }

  dateMask(element) {
    let mask = Inputmask({ mask: '99-99-9999' }).mask(element)
  }

  timeMask(element) {
    let mask = Inputmask({ mask: '99:99' }).mask(element)
  }
}