import NestedForm from "stimulus-rails-nested-form"
export default class extends NestedForm {
  static targets = ['itemId', 'field']

  connect() {
    super.connect();
  }

  add(e) {
    e.preventDefault();
    const type = e.currentTarget.dataset.type
    const dateString = new Date().getTime().toString()
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, dateString);
    let newContent = content.replace(/EQUIPMENT_TYPE/g, type).replace(/EQUIPMENT_PRICE/g, e.currentTarget.dataset.price)
    this.targetTarget.insertAdjacentHTML("beforebegin", newContent);
  }

  remove(e) {
    super.remove(e)
  }

  itemIdTargetConnected(target) {
    let fields = this.fieldTargets;
    fields.forEach((field, i) => {
      let wrapper = field.closest('.nested-form-wrapper')
      if (wrapper.dataset.equipmentTypeValue == target.dataset.type) {
        let option = new Option(target.dataset.name, target.dataset.id, false, field.value == '');
        field.add(option)
      }
    });
    if (this.hasTemplateTarget) {
      const fieldsInTemplate = this.templateTarget.content.querySelectorAll("[data-equipment-form-target='field']");
      fieldsInTemplate.forEach((field, i) => {
        let wrapper = field.closest('.nested-form-wrapper')
        if (wrapper.dataset.equipmentTypeValue == target.dataset.type) {
          let option = new Option(target.dataset.name, target.dataset.id, false, field.value == '');
          field.add(option)
        }
      });
    }
  }
}
