import { Controller } from '@hotwired/stimulus'
import * as Rails from '@rails/ujs';
import { timeAgo, getControllerByIdentifier } from 'helpers';
import { isAvalableLoadSelected, setSelectedLoad } from '../helpers/truck_load';
import { get, post } from '@rails/request.js'
export default class extends Controller {
  loader;
  refreshingTimeInterval;

  static targets = ['pagination', 'column', 'units', 'form',
                    'etaColum', 'unitsColumn', 'truckLoadsColumn', 'unitsTable', 'dispatchBoard' ]
  static values = { refreshingTime: String }

  connect() {
    this.loader = document.querySelector('.page-loader');
    setSelectedLoad();
    this.resizeColumnsWidth()
    this.resizeRowsHeight()
  }

  resizeColumnsWidth() {
    if (!this.hasTruckLoadsColumnTarget) { return }

    const resizers = this.truckLoadsColumnTarget.querySelectorAll('.resizer.vertical');

    let startX = 0;
    let startWidth = 0;
    const minimumSize = 10

    const mouseDownHandler = (e) => {
      e.preventDefault()
      startX = e.clientX;
      const styles = window.getComputedStyle(this.truckLoadsColumnTarget);
      startWidth = parseInt(styles.width, 10);
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = (e) => {
      const endWidth = e.clientX - startX;
      let columnWidth = startWidth + endWidth
      if (columnWidth > minimumSize) {
        this.truckLoadsColumnTarget.style.width = `${columnWidth}px`;
        this.unitsColumnTarget.style.width = `calc(100% - ${columnWidth}px)`;
        localStorage.setItem('truckLoadsColumnWidth', columnWidth)
      }
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    resizers.forEach((resizer) => {
      resizer.addEventListener('mousedown', mouseDownHandler);
    })
  }

  resizeRowsHeight() {
    const resizers = document.querySelectorAll('.resizer.horizontal');
    let wrapper = null;
    let rowTop = null;
    let startY = 0;
    let startHeight = 0;
    const minimumSize = 10

    const mouseDownHandler = (e) => {
      wrapper = e.currentTarget.closest('.column-view')
      rowTop = wrapper.querySelector('.row.top')
      e.preventDefault()
      startY = e.clientY;
      const styles = window.getComputedStyle(rowTop);
      startHeight = parseInt(styles.height, 10);

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = (e) => {
      const endHeight = e.clientY - startY;
      let rowHeight = startHeight + endHeight
      if (rowHeight > minimumSize) {
        rowTop.style.height = `${rowHeight}px`;
        let column = rowTop.closest('.column-view')
        let bottom = column.querySelector('.row.bottom')
        let bottomHeight = column.offsetHeight - rowHeight - 8
        bottom.style.height = `${bottomHeight}px`
        let columnName = rowTop.closest('.column').dataset.name
        localStorage.setItem(`${columnName}RowTopHeight`, rowHeight)
        localStorage.setItem(`${columnName}RowBottomHeight`, bottomHeight)
      }
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    resizers.forEach((resizer) => {
      resizer.addEventListener('mousedown', mouseDownHandler);
    })
  }

  load(event) {
    const { target } = event
    if (!target.value) { return }
    window.location.href = target.value;
  }

  dispatchBoardTargetConnected(target){
    this.resizeColumnsWidth()
    this.resizeRowsHeight()
  }

  truckLoadsColumnTargetConnected(target) {
    if (localStorage.getItem('truckLoadsColumnWidth')) {
      let columnWidth = localStorage.getItem('truckLoadsColumnWidth')
      this.truckLoadsColumnTarget.style.width = `${columnWidth}px`;
      this.unitsColumnTarget.style.width = `calc(100% - ${columnWidth}px)`;
    }
    if (localStorage.getItem('truckLoadsRowTopHeight')) {
      target.querySelector('.row.top').style.height = `${localStorage.getItem('truckLoadsRowTopHeight')}px`;
      target.querySelector('.row.bottom').style.height = `${localStorage.getItem('truckLoadsRowBottomHeight')}px`;
    }
  }

  unitsColumnTargetConnected(target) {
    if (localStorage.getItem('unitsRowTopHeight')) {
      target.querySelector('.row.top').style.height = `${localStorage.getItem('unitsRowTopHeight')}px`;
      target.querySelector('.row.bottom').style.height = `${localStorage.getItem('unitsRowBottomHeight')}px`;
    }
  }

  resetView(event) {
    event.preventDefault;
    localStorage.removeItem('unitsRowTopHeight');
    localStorage.removeItem('unitsRowBottomHeight');
    localStorage.removeItem('truckLoadsRowTopHeight');
    localStorage.removeItem('truckLoadsRowBottomHeight');
    localStorage.removeItem('truckLoadsColumnWidth');
  }

  refreshingTimeTargetConnected(target) {
    const rTime = Date.parse(target.dataset.refreshingTime);
    let now = Date.now()
    if (now - rTime > 86400000) { return }
    this.refreshingTimeInterval = setInterval(function() {
      target.textContent = timeAgo(rTime);
    }, 60000);
  }

  refreshingTimeTargetDisconnected() {
    clearInterval(this.refreshingTimeInterval);
  }

  filterUnit(event) {
    let controller = this
    const form = controller.formTarget
    let dData =  new FormData(form);
    if (!isAvalableLoadSelected()) {
      controller.removeRowSelection();
    }
    controller.getUnits({ url: form.action, dData: dData, showAll: true });
  }

  async getUnits(params={}) {
    let controller = this
    controller.loader.style.display = 'flex';
    let mapsController = getControllerByIdentifier(controller, 'gmaps');
    const response = await post(params.url, {
      query: params.dData,
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
     controller.loader.style.display = 'none';
      if (isAvalableLoadSelected() && params.request != 'byEta') {
        mapsController.onAvailabaleSelect()
      } else if (params.showAll) {
        mapsController.loadPins()
      }
    } else {
      controller.loader.style.display = 'none';
      console.log(response.statusCode);
    }
  }

  getUnitsByEta(params={}) {
    let controller = this
    let url = '/dispatches/units_by_eta';
    let dData =  new FormData();
    if (params.itemId) {
      dData.set('truck_load_id', params.itemId);
      dData.set('scope', 'dispatches_units');
      dData.set('sort', 'eta');
      dData.set('sort_order', 'asc');
    } else if (params.lat &&  params.lng) {
      dData.set('lat', params.lat);
      dData.set('lng', params.lng);
    } else if (params.getUnits) {
      console.log(params);
    } else {
      console.error('getUnitsByEta() got bad parameters.');
      return;
    }
    controller.getUnits({url: url, dData: dData, request: 'byEta'})
  }

  onChangeTruckLoadStatus(event) {
    let itemId = event.currentTarget.dataset.itemId
    let status = event.currentTarget.value;
    let url = `/truck_loads/${itemId}`
    let dData =  new FormData();

    dData.set('status', status);
    Rails.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: url,
      data: dData,
      success: (response) => {
        // this.driversTarget.innerHTML = response.drivers
        // this.paginationTarget.innerHTML = response.pagination
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR)
      }
    })
  }

  onSelectRow(event) {
    let controller = this
    let gmapsController = getControllerByIdentifier(controller, 'gmaps');
    let icon = document.createElement('i');
    icon.classList.add('las', 'la-check');
    let dataRow = event.currentTarget.closest('tr');

    let currentItem = event.currentTarget.dataset.item;
    let currentItemId = event.currentTarget.dataset.itemId;
    let viewType = event.currentTarget.dataset.viewType;
    let isSelectedItem = dataRow.classList.contains('selected');
    let previousSelection =  localStorage.getItem('loadType');

    // controller.dispatch('click', { detail: { content: currentItemId } })

    this.removeRowSelection();
    if (!isSelectedItem) {
      localStorage.setItem('selectedItemId', currentItemId);
      localStorage.setItem('selectedItem', currentItem);
      if (viewType == 'available') {
        localStorage.setItem('loadType', 'available');
      } else if (viewType == 'enroute') {
        localStorage.setItem('loadType', 'enroute');
      }
      dataRow.classList.add('selected');
      let status = event.currentTarget.getElementsByClassName('status-circle');
      status[0].style.display = 'none';
      event.currentTarget.appendChild(icon);
    }
    if (isAvalableLoadSelected()) {
      controller.getUnitsByEta({ itemId: currentItemId });
    } else if (previousSelection == 'available' && isSelectedItem) {
      controller.getUnitsByEta({ itemId: '', getUnits: 'true' });
    } else if (previousSelection == 'available' && viewType != 'available') {
      controller.getUnitsByEta({ itemId: '', getUnits: 'true' });
    } else {
      controller.getUnitsByEta({ itemId: '' });
    }
    gmapsController.clearSearchBox();
  }

  removeRowSelection() {
    let allSelectedRows = document.querySelectorAll('tbody .selected');
    allSelectedRows.forEach(row => {
      row.classList.remove('selected');
      let status = row.getElementsByClassName('status-circle');
      let tdStatus = row.querySelector('td.status i');
      tdStatus.remove();
      status[0].style.display = 'inline-block';
    })

    this.resetSelectedItemLocalStorage()
  }

  resetSelectedItemLocalStorage() {
    localStorage.setItem('selectedItemId', '');
    localStorage.setItem('selectedItem', '');
    localStorage.setItem('loadType', '');
  }

  refreshUnitsLocation() {
    get('/maps/refresh_units_location', {})
  }

}
