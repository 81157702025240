import { Controller } from '@hotwired/stimulus'
import isEmpty from 'lodash/lodash';
import { getControllerByIdentifier } from 'helpers';


export default class extends Controller {
  static targets = ['form', 'required']
  effectsController;
  loader;

  connect() {
    this.loader = document.querySelector('.page-loader');
    this.effectsController = getControllerByIdentifier(this, 'effects');
  }

  validate(event) {
    if (event.detail.formSubmission.formElement != this.formTarget) { return }
    let errors = []
    this.requiredTargets.forEach((field, i) => {
      if (_.isEmpty(field.value)) {
        let name = field.closest('.field').querySelector('label').textContent.replace(/\n/g, "").replace('+', '')
        let p = document.createElement('p')
        p.textContent = `${name} can't be blank`
        errors.push(p)
        if (field.tomselect) {
          field.closest('.field').classList.add('with-error')
        } else {
          let wrapError = document.createElement('div');
          wrapError.classList.add('field_with_errors')
          field.parentNode.insertBefore(wrapError, field);
          wrapError.appendChild(field)
        }
      }
    })
    if (!_.isEmpty(errors)) {
      event.detail.formSubmission.stop()
      this.loader.style.display = 'none';
      this.effectsController.flash( { detail: {
        message: errors, wrapperClass: 'alert-error', icons: ['las', 'la-exclamation-triangle']
      }})
    }
  }
}
