import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  connect() {
    super.connect()
  }

  add(e) {
    e.preventDefault()
    const dateString = new Date().getTime().toString();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, dateString)
    this.targetTarget.insertAdjacentHTML('beforebegin', content);
  }

  remove(e) {
    super.remove(e)

    const element = e.target.closest(this.wrapperSelectorValue);
    delete element.dataset.tlWarehousesTarget
    if (element.dataset.newRecord != "true") {
      let id = e.currentTarget.dataset.id
      let tableRow = document.getElementById('truck_load_warehouse_' + id);
      if (tableRow) { tableRow.style.display = "none" };
    }
  }
}
