export function scriptExists(url) {
  return document.querySelectorAll(`script[src="${url}"]`).length > 0;
}
export function mapDetailsType() {
  let selectedItemId = localStorage.getItem('selectedItemId');
  let selectedItem = localStorage.getItem('selectedItem');
  let ret = {}
  if (selectedItemId != '') {
    let row = document.querySelector(`.dispatches-board table tr#${selectedItem}_${selectedItemId}`);
    if (row && row.classList.contains('selected')) {
      let table = row.closest('.table')
      ret = { type: table.dataset.loadType, selectedItemId: selectedItemId, selectedItem: selectedItem }
    } else {
      ret = { type: 'default', selectedItemId: '', selectedItem: '' }
    }
  } else {
    ret = { type: 'default', selectedItemId: '', selectedItem: '' }
  }
  return ret;
}

export function insertGoogleMap(apiKey) {
  const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=dispatchMapsEvent`;
  const script = document.createElement('script')
  if (scriptExists(url)) { return }

  script.src = url;
  script.async = true
  script.defer = true

  document.head.appendChild(script)
}