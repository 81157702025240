import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  transformToInputField(selectbox) {
    var inputField = document.createElement("input")
    inputField.type = 'text'
    inputField.placeholder = 'Enter new option'

    selectbox.parentNode.replaceChild(inputField, selectbox)
    selectbox.style.backgroundImage = "none"
    inputField.focus()
    return inputField
  }

  setOption(event) {
    let selectbox = event.currentTarget
    if (selectbox.value === '+ Custom') {
      var inputField = this.transformToInputField(selectbox)

      inputField.addEventListener('blur', function() {
        var value = inputField.value.trim();

        if (value != '') {
          let option = new Option(value, value, true, true)
          selectbox.add(option, 1);
        }
        else {
          selectbox.value = 'Select...'
        }
        inputField.parentNode.replaceChild(selectbox, inputField)
        inputField.style.display = 'none';
        selectbox.style.display = 'inline-block';
      })
    }
  }
}
