import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  autoLoad = false;
  static targets = ['entries', 'pagination']

  connect() {
    const controller = this
    var body = document.body
    if (window.innerHeight > body.offsetHeight ) {
      let url = controller.paginationTarget.querySelector("a[rel='next']")?.href
      if (url) {
        controller.autoLoad = true;
        controller.loadMore(url);
      }
    }
  }

  scroll() {
    const controller = this
    controller.autoLoad = false;
    let url = controller.paginationTarget.querySelector("a[rel='next']")?.href
    var body = document.body
    var html = document.documentElement
    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight )
    if (window.pageYOffset >= height - window.innerHeight - 30 ) {
      if (url) controller.loadMore(url);
    }
  }

  loadMore(url) {
    const controller = this;
    controller.paginationTarget.innerHTML = ''
    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: url,
      success: (response) => {
        controller.entriesTarget.insertAdjacentHTML('beforeend', response.entries);
        controller.paginationTarget.innerHTML = response.pagination;
        let nextUrl = controller.paginationTarget.querySelector("a[rel='next']")?.href
        var body = document.body
        if (nextUrl && controller.autoLoad && (window.innerHeight > body.offsetHeight )) {
          controller.loadMore(nextUrl)
        } else {
          controller.autoLoad = false;
        }
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR);
        controller.autoLoad = false;
      }
    })
  }
}
