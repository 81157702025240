import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['assignees', 'percentage', 'status']

  setAssignees(event) {
    let assignees = Array.from(this.assigneesTarget.options)
      .filter(option => option.dataset.departmentId == event.currentTarget.value)
      .map(m => m.value)
    this.assigneesTarget.slim.setSelected(assignees)
  }

  onPercentageChange(event) {
    let percentage = parseInt(event.currentTarget.value)

    if (percentage === 100) {
      this.statusTarget.value = 'completed'
    } else {
      this.statusTarget.value = 'in_progress'
    }
  }
}
