import { Controller } from '@hotwired/stimulus';
import { getClosestNumber } from 'helpers';

export default class extends Controller {
  static targets = ['shipper', 'receiver', 'highValueField', 'formValue', 'receiverFormValue'];

  highValue = 250000
  copyDataFromShipperToReceiver = true
  connect() {
    // this.setHighValueFieldVisibility()
  }

  shipperTargetConnected() {
    this.copyDataFromShipperToReceiver = (this.shipperTargets.length >= 1) && (this.receiverTargets.length == 1)
  }

  highValueFieldTargetConnected(target) {
    let warehouse = target.closest('.nested-form-wrapper')
    this.highValueFieldVisibility(warehouse)
  }

  receiverTargetConnected() {
    this.copyDataFromShipperToReceiver = (this.shipperTargets.length >= 1) && (this.receiverTargets.length == 1)
  }

  shipperTargetDisconnected() {
    this.copyDataFromShipperToReceiver = (this.shipperTargets.length >= 1) && (this.receiverTargets.length == 1)
  }
  receiverTargetDisconnected() {
    this.copyDataFromShipperToReceiver = (this.shipperTargets.length >= 1) && (this.receiverTargets.length == 1)
  }

  formValueTargetConnected(target) {
    let controller = this
    let warehouse = target.closest('.nested-form-wrapper')
    let valueField = warehouse.querySelector("[data-field='value']")

    target.addEventListener('change', (event) => {
      let field = event.currentTarget.dataset.field
      let value = event.currentTarget.value
      valueField.value = value == '-1' ? '' : event.currentTarget.value
      if (this.copyDataFromShipperToReceiver) {
        this.valueFromShipperToReceiver(field)
      }
      valueField.dispatchEvent(new Event('change'))
      controller.setHighValueFieldVisibility()
    });
  }

  shipperFieldTargetConnected(target) {
    target.addEventListener('change', (event) => {
      let field = event.currentTarget.dataset.field
      if (this.copyDataFromShipperToReceiver) {
        this.setDataToReceiver(field)
      }
    });
  }

  receiverFormValueTargetConnected(target) {
    let warehouse = target.closest('.nested-form-wrapper')
    target.addEventListener('change', (event) => {
      this.highValueFieldVisibility(warehouse)
    })
  }

  setDataToReceiver(field) {
    let value = (field == 'package_type') ? '' : 0
    let receiverField = this.receiverTarget.querySelector(`[data-field='${field}']`)
    this.shipperTargets.forEach(shipper => {
      if (field != 'package_type') {
        let currentValue = shipper.querySelector(`[data-field='${field}']`).value * 1
        value += currentValue
      } else {
        let pValue = shipper.querySelector(`[data-field='${field}']`).value
        if (pValue != '') { value = pValue }
      }
    });
    if ((field == 'value') && value <= this.highValue) {
      const values = this.formValueOptionsValues()
      const closestValue = getClosestNumber(values, value)
      receiverField.value = closestValue
    } else {
      receiverField.value = value
    }
  }

  valueFromShipperToReceiver(field) {
    let value = 0
    let receiverField = this.receiverTarget.querySelector(`[data-field='${field}']`)
    const values = this.formValueOptionsValues()

    this.shipperTargets.forEach(shipper => {
      let currentValue = shipper.querySelector(`[data-field='${field}']`).value * 1
      if (currentValue == -1) { currentValue = this.highValue + 1 }
      value += currentValue

      const closestValue = getClosestNumber(values, value)
      receiverField.value = (value > this.highValue) ? -1 : closestValue
    })
  }

  setReceiverValue(event) {
    let warehouse = event.currentTarget.closest('.nested-form-wrapper')
    let valueField = warehouse.querySelector("[data-field='value']")

    if (event.currentTarget.value > this.highValue) { return }
    if (event.currentTarget.value == '-1') {
      valueField.value = ''
      return
    }
    valueField.value = event.currentTarget.value
  }

  setHighValueFieldVisibility() {
    this.shipperTargets.forEach(shipper => {
      this.highValueFieldVisibility(shipper)
    })
    this.receiverTargets.forEach(receiver => {
      this.highValueFieldVisibility(receiver)
    })
  }

  highValueFieldVisibility(warehouse) {
    let value = warehouse.querySelector("[data-field='form_value']").value
    let highValueInput = warehouse.querySelector("[data-field='value']")
    let highValueField = highValueInput.closest('.field')

    if (value == '') {
      highValueField.classList.add('hidden')
      return;
    }
    if ((value*1 > this.highValue) || (value*1 == -1)) {
      highValueField.classList.remove('hidden')
      if (value*1 == -1) { highValueInput.value = '' }
    } else {
      highValueField.classList.add('hidden')
    }
  }

  formValueOptionsValues() {
    let field = this.element.querySelector("[data-field='form_value']")
    return [...field.options].map(el => el.value).filter(elm => elm).map(Number)
  }
}
