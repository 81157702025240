import { Controller } from '@hotwired/stimulus'
import * as Rails from '@rails/ujs';
import * as TrimbleMaps from "@trimblemaps/trimblemaps-js";

export default class extends Controller {
  map;
  popup;
  unitsColumn;
  static targets = ['column']

  connect() {
    let controller = this;

    this.unitsColumn = document.getElementById('units_column');
    controller.loadMap()
  }

  setMap() {
    TrimbleMaps.APIKey = "AE17EEE82FFE4D4CA62B56E92C7EB4EC";
    this.map = new TrimbleMaps.Map({
      container: "dispatches_map",
      center: new TrimbleMaps.LngLat(-96, 35),
      zoom: 4
    });
    // this.setupEvents();
  }

  loadMap (event) {
    let controller = this;
    if (controller.unitsColumn.classList.contains('visible')) {
      controller.unitsColumn.addEventListener('transitionend', function() {
        if (controller.map == null) {
          controller.setMap()
        }
      });
    }
  }

  setupEvents() {
    let controller = this;
    this.map.on('load', function() {
      controller.loadPins();
    });
    this.map.on('mouseenter', 'points', function(event) {
      controller.overHandler(event, controller)
    });
    this.map.on('mouseleave', 'points', function() {
      controller.map.getCanvas().style.cursor = '';
      controller.popup.remove()
    });
  }

  loadPins() {
    let controller = this;
    let url = '/maps/all_units_location';
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      success: (response) => {
        const data = controller.prepareGeoJsonData(response.drivers)
        const center = controller.calculateCenter(response.drivers);

        controller.map.setCenter([center.lng, center.lat])
        controller.map.addSource('allDriversLocation', controller.basicGeoJsonSource(data));
        controller.map.addLayer(controller.layerClusterPoint());
        controller.map.addLayer(controller.layerClusterCount());
        controller.map.addLayer(controller.layerPoints());
      },
      error: (XHR, textStatus, errorThrown) => {
        console.log(XHR)
      }
    })
  }

  overHandler(event, controller) {
    controller.map.getCanvas().style.cursor = 'pointer';
    const popupLocation = event.features[0].geometry.coordinates.slice();
    let popupContent = '<p>' + event.features[0].properties.unit + ' &middot; ' + event.features[0].properties.name + '</p>'

    controller.popup = new TrimbleMaps.Popup()
      .setLngLat(popupLocation)
      .setHTML(popupContent)
      .addTo(controller.map);
  };

  calculateCenter(points) {
    const allLng = points.map(point =>  parseFloat(point.lng))
    const allLat = points.map(point => parseFloat(point.lat))

    const swLng = Math.min.apply(Math, allLng)
    const swLat = Math.min.apply(Math, allLat)

    const neLng = Math.max.apply(Math, allLng)
    const neLat = Math.max.apply(Math, allLat)
    let sw = new TrimbleMaps.LngLat(swLng, swLat);
    let ne = new TrimbleMaps.LngLat(neLng, neLat);

    let llb = new TrimbleMaps.LngLatBounds(sw, ne);
    let center = llb.getCenter();

    return center;
  }
  prepareGeoJsonData(pins) {
    const features = [];
    pins.forEach(pin => {
      features.push({
          type: 'Feature',
          properties: {
            id: pin.id,
            name: pin.driver,
            unit: pin.unit_number,
            category: pin.type
          },
          geometry: {
            type: 'Point',
            coordinates: [pin.lng, pin.lat]
          }
      });
    });
    return features;
  }

  basicGeoJsonSource(data) {
    return {
      type: 'geojson',
      cluster: true,
      clusterRadius: 20,
      clusterMaxZoom: 14,
      data: {
        type: 'FeatureCollection',
        features: data
      }
    }
  }
  layerClusterPoint() {
    return {
      id: 'clusteredPoints',
      type: 'circle',
      source: 'allDriversLocation',
      filter: ['has', 'point_count'],
      paint: {
        'circle-radius': [ 'step', ['get', 'point_count'], 15, 5, 20, 50, 25 ],
        'circle-color': '#262f36',
        'circle-stroke-color': '#FFF',
        'circle-stroke-width': 3
      }
    }
  }

  layerClusterCount() {
    return {
      id: 'clusterCount',
      type: 'symbol',
      source: 'allDriversLocation',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count}',
        'text-font': ['Roboto Regular'],
        'text-size': 16
      },
      paint: {
        'text-color': '#FFF'
      }
    }
  }

  layerPoints(type, controller) {
    return {
      id: 'points',
      type: 'circle',
      source: 'allDriversLocation',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-radius': 14,
        'circle-color': [ 'match', ['get', 'category'],
          'moving', '#62b351',
          'idling', '#e8c22e',
          'stationary', '#4d5e6c',
          'stale', '#bec8d1',
          '#ff0000'
        ],
        'circle-stroke-color': '#FFF',
        'circle-stroke-width': 2
      }
    }
  }
}
